import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlaceholderType } from '@models/placeholder';
import { ContentPlaceholderService } from '@services/content-placeholder.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-placeholder-container',
  templateUrl: './placeholder-container.component.html',
  styleUrls: ['./placeholder-container.component.css']
})
export class PlaceholderContainerComponent implements OnInit, OnDestroy {

  currentPlaceholder: PlaceholderType;
  readonly currenPlaceholderTypes = PlaceholderType;
  subscription: Subscription;
  isLoadingPlaceholder: boolean;

  constructor( private contentPlaceHolderService: ContentPlaceholderService ) { }

  ngOnDestroy(): void {
    this.subscription
      .unsubscribe();
  }

  ngOnInit(): void {
    this.setListener();
  }

  setListener() {
    this.subscription =
    this.contentPlaceHolderService
      .getContentPlaceHolderStatus()
      .subscribe( data => {
        this.isLoadingPlaceholder = data.loading;
        this.currentPlaceholder = data.placeholderType;
      })
  }

  

}

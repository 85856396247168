import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { OffersByDepartmentComponent } from '../offers-by-department.component';

// module
import { OrderByCriteriaOffcanvasModule } from '@components/order-by-criteria-offcanvas/module/order-by-criteria-offcanvas.module';
import { PlaceholderContainerModule } from '@components/shared/placeholder/placeholder-container/module/placeholder-container.module';
import { MyBackToTopModule } from '@components/my-back-to-top/module/my-back-to-top.module';
import { OfferCardContainerModule } from '@components/shared/offer-card-container/module/offer-card-container.module';
import { DeparmentScrollingComponentModule } from '@components/deparment-scrolling-component/module/deparment-scrolling-component.module';
import { JsonLdModule } from '@components/json-ld/module/json-ld.module';
import { TypeaheadOffcanvasModule } from '@components/typeahead-offcanvas/module/typeahead-offcanvas.module';

// pipes
import { PipesModule } from '@pipes/module/pipes.module';

@NgModule({
  declarations: [
    OffersByDepartmentComponent
  ],
  imports: [
    CommonModule,
    OrderByCriteriaOffcanvasModule,
    PlaceholderContainerModule,
    MyBackToTopModule,
    PipesModule,
    OfferCardContainerModule,
    DeparmentScrollingComponentModule,
    JsonLdModule,
    TypeaheadOffcanvasModule
  ],
  exports: [
    OffersByDepartmentComponent
  ]
})
export class OffersByDepartmentModule { }

export interface OrderByDefinition{
    name: OrderByName;
    value: OrderByType;
    iconTemplate: string;
    divider: boolean;
    config: OrderPipeConfig;
    hint: string;
}

export interface OrderPipeConfig{
  expression: string,
  reverse: boolean,
  caseInsensitive:boolean,
  comparator?: Function | undefined
}

export interface OrderByConfig{
    orderByCriteria: OrderByField,
    orderIsReverse: boolean,
    orderByComparator: Function
}

export enum OrderByFieldDescription{
  lastest = 'offerId',
  price = 'offerPrice',
  name = 'title',
  discountDescribed = 'discountDescribed',
  discount = 'discount',
  reserved = 'reserved',
}

export enum OrderByType{
    lastest,
    highDiscount,
    reerved,
    shuffle,
    nameAZ,
    nameZA,
}

export enum OrderByName{
    lastest = 'Ultimas',
    highDiscount = 'Mayor descuento',
    reserved = 'Más canjeadas',
    shuffle = 'Aleatorio',
    nameAZ = 'Nombre A - Z',
    nameZA = 'Nombre Z - A',
}


export enum OrderByField{
    lastest = 'date',
    price = 'price',
    name = 'title',
}

export enum OrderByUrl{
    lastest = 'order-by-lastest',
    price = 'order-by-price'
}
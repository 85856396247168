//ng modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// pipes
import { PipesModule } from './pipes/module/pipes.module';

// directives
import { DirectivesModule } from '@directives/directives.module';

//modules
import { NavbarModule } from '@components/shared/navbar/module/navbar.module';
import { OffersListModule } from '@components/offer-list/module/offers-list.module';
import { OffersByDepartmentModule } from '@components/offers-by-department/module/offers-by-department.module';
import { OffersByMarketingCampaingModule } from '@components/offers-by-marketing-campaing/module/offers-by-marketing-campaing.module';

//environment
import { environment } from '@environment';

// 3-party
import { ScullyLibModule } from '@scullyio/ng-lib';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    DirectivesModule,
    NavbarModule,
    OffersListModule,
    OffersByDepartmentModule,
    OffersByMarketingCampaingModule,
    ScullyLibModule.forRoot({
      useTransferState: true,
      // alwaysMonitor: true,
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor() {
  }
}

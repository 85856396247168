import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizerPipe } from '../dom-sanitizer.pipe';
import { ImageSizePipe } from '../image-size.pipe';
import { WordToSlugPipe } from '../word-to-slug.pipe'
import { SlugToWordPipe } from '../slug-to-word.pipe';
import { TimeLeftPipe } from '../time-left.pipe';
import { UpdatedAgoPipe } from '../updated-ago.pipe';
import { ReplacePipe } from '../replace.pipe'
import { TitlePipe } from '../title.pipe';
import { RatingPipe } from '../rating.pipe';
import { PercentegePipe } from '../percentege.pipe';
import { PricePipe } from '../price.pipe';
import { StarRatingPipe } from '../star-rating.pipe';
import { ImagesArrayPipe } from '@pipes/images-array.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DomSanitizerPipe,
    ImageSizePipe,
    WordToSlugPipe,
    SlugToWordPipe,
    TimeLeftPipe,
    UpdatedAgoPipe,
    ReplacePipe,
    TitlePipe,
    RatingPipe,
    PercentegePipe,
    PricePipe,
    StarRatingPipe,
    ImagesArrayPipe
  ],
  exports: [
    DomSanitizerPipe,
    ImageSizePipe,
    WordToSlugPipe,
    SlugToWordPipe,
    TimeLeftPipe,
    UpdatedAgoPipe,
    ReplacePipe,
    TitlePipe,
    RatingPipe,
    PercentegePipe,
    PricePipe,
    StarRatingPipe,
    ImagesArrayPipe
  ]
})
export class PipesModule { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-amazon-mexico',
  templateUrl: './amazon-mexico.component.html',
  styleUrls: ['./amazon-mexico.component.css']
})
export class AmazonMexicoComponent implements OnInit {

  @Input() referLink: string;

  constructor() { }

  ngOnInit(): void {
  }

}

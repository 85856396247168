import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OffersAPI } from '@models/offers';

@Component({
  selector: 'app-json-ld',
  templateUrl: './json-ld.component.html',
  styleUrls: ['./json-ld.component.css']
})
export class JsonLdComponent implements OnInit {

  @Input() arr: OffersAPI[];
  @Input() title: string;
  @Input() description: string;
  jsonLdSchema: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const arrayRes = this.arr.map((el, index) => {
      if (el.title) {
        return {
          "@type": "ListItem",
          "position": index + 1,
          "url": el.url,
        }
      }
    });

    // generated from https://schema.org/itemListElement
    const jsonTemplate = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "url": location.pathname,
      "name": this.description,
      "numberOfItems": this.arr.length + 1,
      "itemListElement": arrayRes
    };

    const resultTemplate = `
    <script type="application/ld+json">
      ${JSON.stringify(jsonTemplate)}
    </script>`;

    this.jsonLdSchema = this.sanitizer.bypassSecurityTrustHtml(resultTemplate);
  }

}

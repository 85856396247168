import { 
Component, EventEmitter, HostListener, Input, OnInit, Output 
} from '@angular/core';

// interfaces
import { 
  OrderByConfig, OrderByDefinition, OrderByField, OrderByName, OrderByType 
} from '@models/orderBy';

// services
import { OrderByService } from '@services/order-by.service';

// bootstrap
declare var bootstrap;

@Component({
  selector: 'app-order-by-criteria-offcanvas',
  templateUrl: './order-by-criteria-offcanvas.component.html',
  styleUrls: ['./order-by-criteria-offcanvas.component.css']
})
export class OrderByCriteriaOffcanvasComponent implements OnInit {
  
  @HostListener('show.bs.offcanvas')
  onShowOffcanvas() {
    window.location.hash = this.currentComponentUrlHash;
  }

  @HostListener('hide.bs.offcanvas')
  onHideOffcanvas() {
    // remove hash from url https://stackoverflow.com/a/5298684/7724301
    history.pushState("", document.title, window.location.pathname + window.location.search);
  }

  @HostListener('window:hashchange', ['$event'])
  onHashChange() {
    if (window.location.hash != `#${this.currentComponentUrlHash}`) {
      this.closeOffcanvas();
    }
  }

  currentComponentUrlHash = 'order-by-criteria'; 
  orderByCriteria: OrderByField;
  orderByCriteriaCurrent: OrderByDefinition;
  orderIsReverse: boolean = false;
  orderByComparator: Function;
  currentOrderByConfig: OrderByConfig;
  currentSelectedOption: OrderByDefinition;
  orderCriteriasOptions: OrderByDefinition[];
  orderByType = OrderByType;

  @Output() onChangedOrderByCriteria: EventEmitter<OrderByDefinition> = new EventEmitter<OrderByDefinition>();
  @Input() currentPage: number;

  constructor(
    private orderByService: OrderByService
  ) { 
    this.orderCriteriasOptions = this.orderByService.getAllOrderByOptions();
    this.currentSelectedOption = this.orderByService.getCurrentOrderBy();
  }

  ngOnInit(): void {
    this.validateDefaultOrderBy();
  }

  changeOrderBy(item: OrderByDefinition) {
    if (this.currentSelectedOption.name != item.name) {
      this.applyOrderBy(item);
    }
  }

  validateDefaultOrderBy() {
    if (!this.currentSelectedOption) {
      const curentOrderByLastest: OrderByName = OrderByName.lastest;
      this.orderByService.setCurrentOrderBy(this.orderByService.getOrderByOption(curentOrderByLastest))
      this.currentSelectedOption = this.orderByService.getCurrentOrderBy();
    }
  }

  shuffle() {
    const shuffleOrderBy = this.orderByService.getAllOrderByOptions().find(el => el.name === OrderByName.shuffle);
    this.applyOrderBy(shuffleOrderBy);
  }

  applyOrderBy(item: OrderByDefinition) {
    this.orderByService.setCurrentOrderBy(item);
    this.currentSelectedOption = item;
    this.onChangedOrderByCriteria.emit(this.currentSelectedOption);
  }

  closeOffcanvas() {
    const myOffcanvas = bootstrap.Offcanvas.getOrCreateInstance(document.getElementById('offcanvasExample'));
    myOffcanvas.hide();
  }

}

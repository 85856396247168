import { MarketingCampaingInternalDetails } from "./marketing-campaing";

export const hotSaleMarketingCampaing: MarketingCampaingInternalDetails = {
  route: 'hot-sale',
  title: 'Hot Sale 2023 🔥🛍️ ¡Aprovecha nuestras increíbles ofertas! 🤩😍',
  description: '☝ 🔥 【Ofertas】➨ Descuentos exclusivos en una gran variedad de productos. ¡Renueva tu hogar, estilo de vida y más a precios imperdibles! 🤑 ⌛'
}

export const elBuenFin: MarketingCampaingInternalDetails = {
  route: 'el-buen-fin',
  title: 'lorem ipsum',
  description: 'lorem ipsum'
}

export const blackFriday: MarketingCampaingInternalDetails = {
  route: 'black-friday',
  title: 'lorem ipsum',
  description: 'lorem ipsum'
}

export const amazonPrime: MarketingCampaingInternalDetails = {
  route: 'amazon-prime',
  title: 'lorem ipsum',
  description: 'lorem ipsum'
}

export const primeDay: MarketingCampaingInternalDetails = {
  route: 'prime-day',
  title: 'lorem ipsum',
  description: 'lorem ipsum'
}
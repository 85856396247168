import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// environment
import { environment } from '@environment';

// interface
import { OfferAPIResponse } from '@models/offers';

// rxjs
import { Observable } from 'rxjs';

// scully
import { TransferStateService } from '@scullyio/ng-lib';

@Injectable({
  providedIn: 'root'
})
export class OffersByDepartmentService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private transferState: TransferStateService
  ) { }

  /**
   * Get offers by department 1. Computers and Accessories
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#cb1ceb6e-cb07-4bb1-9e34-053549b418c7
   */
  getByDepartmentComputersAndAccessories(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentComputersAndAccessories');
    return this.transferState
      .useScullyTransferState(
        'computersAndAccessories', 
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
      );
  }

  /**
   * Get offers by department 2. Electronic accessories
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#85ad8115-0eb1-4acf-8bef-e38dd45f893a
   */
  getByDepartmentGamesAndToys(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentGamesAndToys');
    return this.transferState
      .useScullyTransferState(
        'gamesAndToys',
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
        )
  }
  
  /**
   * Get offers by department 3. Games and Toys
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#00da2109-cbe9-41b0-b6f3-cab950378785
   */
  getByDepartmentElectronicAccessories(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentElectronicAccessories');
    return this.transferState
      .useScullyTransferState(
        'electronicAccessories',
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
      );
  }

  /**
   * Get offers by department 4. Beauty
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#7c651184-f062-427e-84f1-23903776b438
   */
  getByDepartmentBeauty(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentBeauty');
    return this.transferState
      .useScullyTransferState(
        'beauty',
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
      );
  }

  /**
   * Get offers by department 5. Fashion
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#172152a9-44e6-4781-aedd-aa69f4287842
   */
  getByDepartmentFashion(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentFashion');
    return this.transferState
      .useScullyTransferState(
        'fashion',
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
      );
  }

  /**
   * Get offers by department 6. Home and Kitchen
   * https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#4d2965d4-14f8-4a0f-89c5-513aac8d426a
   */
  getByDepartmentHomeAndKitchen(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentHomeAndKitchen');
    return this.transferState
      .useScullyTransferState(
        'homeAndKitchen',
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
      );
  }

  /**
   * Get offers by department 7. Outdoor Sports
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#14902a80-a71e-4e26-b74f-c1c82b23b878
   */
  getByDepartmentOutdoorSports(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentOutdoorSports');
    return this.transferState
      .useScullyTransferState(
        'outdoorSports',
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
      )
  }

  /**
   * Get offers by department 8. Tv and Video
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#bacdab48-d33d-4cb5-961d-8365eb9c9cdc
   */
  getByDepartmentTvAndVideo(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentTvAndVideo');
    return this.transferState
    .useScullyTransferState(
      'tvAndVideo',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
   * Get offers by department 9. Books
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#97a65752-ea60-43df-926c-821ac86a3891
   */
  getByDepartmentBooks(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentBooks');
    return this.transferState.useScullyTransferState(
      'books',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
   * Get offers by department 10. Cellphones and Accessories
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#91c2bbf5-0cc8-4bbc-aa8d-5c12ba7bc8c1
   */
  getByDepartmentCellphonesAndAccessories(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentCellphonesAndAccessories');
    return this.transferState.useScullyTransferState(
      'cellphonesAndAccessories',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }


  /**
   * Get offers by department 11. Personal Care
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#44e85860-63ea-4cf0-afe4-17d6883aa927
   */
  getByDepartmentPersonalCare(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentPersonalCare');
    return this.transferState.useScullyTransferState(
      'personalCare',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
   * Get offers by department 12. Home Improvements
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#e856b776-5166-4866-b6fe-9eee3bc101bf
   */
  getByDepartmentHomeImprovements(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentHomeImprovements');
    return this.transferState.useScullyTransferState(
      'homeImprovements',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
   * Get offers by department 13. Videogames
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#7013f40f-a26f-4dc0-b348-b48e240f7830
   */
  getByDepartmentVideogames(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentVideogames');
    return this.transferState.useScullyTransferState(
      'videogames',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
   * Get offers by department 14. Cameras and Photography
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#bae90a44-b42d-4e36-8176-4caa74f486d8 
   */
  getByDepartmentCamerasAndPhotography(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentCamerasAndPhotography');
    return this.transferState.useScullyTransferState(
      'camerasAndPhotography',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }


  /**
   * Get offers by department 15. Bags and Suitcases
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#0ace1844-0d42-4ca9-be90-bf6144b4093e
   */
  getByDepartmentBagsAndSuitcases(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentBagsAndSuitcases');
    return this.transferState.useScullyTransferState(
      'bagsAndSuitcases',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
   * Get offers by department 16. Furnitures
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#dbb8a00b-b1d0-4634-884a-fc0dba572070
   */
  getByDepartmentFurnitures(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentFurnitures');
    return this.transferState.useScullyTransferState(
      'furnitures',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
   * Get offers by department 17. Baby
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#6cc49dd6-f440-40c6-af71-83a7c4cfab80
   */
  getByDepartmentBaby(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentBaby');
    return this.transferState.useScullyTransferState(
      'baby',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }
  
  /**
   * Get offers by department 18. Pets
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#05016e1d-ba0b-4b75-9ef9-a46336b7faec
   */
  getByDepartmentPets(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentPets');
    return this.transferState.useScullyTransferState(
      'pets',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }
  
  /**
   * Get offers by department 19. Office
   * url https://documenter.getpostman.com/view/6859509/2sA3JF9iWb#d38c18be-1414-43f7-9c9a-70ba3f853248
   */
  getByDepartmentOffice(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffersByDepartmentOffice');
    return this.transferState.useScullyTransferState(
      'office',
      this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }
  

  moveScrollNext() {
    const el = document.getElementById('department-scroll');
    el.scrollBy({
      left: -301,
      behavior: "smooth"
    });
  }

  moveScrollBack() {
    const el = document.getElementById('department-scroll');
    el.scrollBy({
      left: 301,
      behavior: "smooth"
    });
  }

  validateCurrentStage(url: string) {
    if (!environment.production && environment.enableDevStage) {
      return url + 'Dev';
    } else {
      return url;
    }
  }
}

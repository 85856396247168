import { Injectable } from '@angular/core';

// models
import { OrderByDefinition, OrderByFieldDescription, OrderByName, OrderByType } from '@models/orderBy';

@Injectable({
  providedIn: 'root'
})
export class OrderByService {

  constructor() { }

  private orderCriteriasOptions: OrderByDefinition[] = [
    {
      iconTemplate: '<i class="fa-solid fa-fire"></i>',
      name: OrderByName.lastest,
      value: OrderByType.lastest,
      divider: true,
      config: {
        expression: OrderByFieldDescription.lastest,
        reverse: false,
        caseInsensitive: false,
        comparator: undefined
      },
      hint: 'Mostrar ofertas más recientes'
    },
    {
      iconTemplate: '<i class="fa-solid fa-trophy"></i>',
      name: OrderByName.highDiscount,
      value: OrderByType.highDiscount,
      divider: true,
      config: {
        expression: OrderByFieldDescription.discount,
        reverse: true,
        caseInsensitive: false,
        comparator: this.orderbyHighDiscount
      },
      hint: 'Mostrar ofertas con un mayor descuento primero'
    },
    {
      iconTemplate: '<i class="fa-solid fa-star"></i>',
      name: OrderByName.reserved,
      value: OrderByType.reerved,
      divider: false,
      config: {
        expression: OrderByFieldDescription.reserved,
        caseInsensitive: false,
        reverse: true,
        comparator: this.orderByReserved
      },
      hint: 'Mostrar ofertas apunto de finalizar'
    },
    {
      iconTemplate: '<i class="fa-solid fa-dice"></i>',
      name: OrderByName.shuffle,
      value: OrderByType.shuffle,
      divider: true,
      config: {
        expression: OrderByFieldDescription.discountDescribed,
        reverse: false,
        caseInsensitive: false,
        comparator: this.orderByShuffle
      },
      hint: 'Mostrar ofertas al azar'
    },
    {
      iconTemplate: '<i class="fa-solid fa-arrow-up-a-z"></i>',
      name: OrderByName.nameAZ,
      value: OrderByType.nameAZ,
      divider: false,
      config: {
        expression: OrderByFieldDescription.name,
        caseInsensitive: false,
        reverse: false,
        comparator: undefined
      },
      hint: 'Mostrar ofertas en un orden alfabetico'
    },
    {
      iconTemplate: '<i class="fa-solid fa-arrow-down-z-a"></i>',
      name: OrderByName.nameZA,
      value: OrderByType.nameZA,
      divider: false,
      config: {
        expression: OrderByFieldDescription.name,
        caseInsensitive: false,
        reverse: true,
        comparator: undefined
      },
      hint: 'Mostrar ofertas en un orden alfabetico invertido'
    },
  ];
  selectedOrderBy: OrderByDefinition;

  getAllOrderByOptions():OrderByDefinition[]{
    return this.orderCriteriasOptions;
  }

  getOrderByOption(currentOption: OrderByName): OrderByDefinition{
    switch (currentOption) {
      case OrderByName.lastest: 
        return this.orderCriteriasOptions.find(el => el.name === OrderByName.lastest);

      case OrderByName.highDiscount:
        return this.orderCriteriasOptions.find(el => el.name === OrderByName.highDiscount);
      
      case OrderByName.reserved:
        return this.orderCriteriasOptions.find(el => el.name === OrderByName.reserved);
        
      case OrderByName.nameAZ:
        return this.orderCriteriasOptions.find(el => el.name === OrderByName.nameAZ);

      case OrderByName.nameZA:
        return this.orderCriteriasOptions.find(el => el.name === OrderByName.nameZA);

      case OrderByName.shuffle:
        return this.orderCriteriasOptions.find(el => el.name === OrderByName.shuffle);
    }
  }

  getDefaultOrderBy(): OrderByDefinition{
    return this.orderCriteriasOptions[0];
  }

  setCurrentOrderBy(currentOrderBy: OrderByDefinition){
    this.selectedOrderBy = currentOrderBy;
  }

  getCurrentOrderBy():OrderByDefinition{
    return this.selectedOrderBy;
  }

  /**
   * Order by offerprice from lowest to highest
   * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  */
  public orderByPriceLowestToHighest(a:string, b:string){
    const itemA = formatPriceToNumber(a);
    const itemB = formatPriceToNumber(b);
    return itemA > itemB ? 1 : -1;

    /**
     * Convert string offer price into number
     * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
    */
    function formatPriceToNumber(price: string) {
      if (price && price.includes('–')) {
        return +price.split('–')[0].replace(',', '').replace('$', '');
      }
      else if (price) {
        return +price.replace(',', '').replace('$', '');
      }
      else {
        return 999999; //return highest price
      }
    }
  }
  
  /**
   * Order by offerprice from highest to lowest
   * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  */
  public orderByPriceHighestToLowest(a:string, b:string){
    const priceA = a.replace('-','').replace('%', '');
    const priceB = b.replace('-','').replace('%', '');
    const itemA = formatPriceToNumber(priceA);
    const itemB = formatPriceToNumber(priceB);
    return itemA > itemB ? 1 : -1;

    /**
     * Convert string offer price into number
     * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
    */
    function formatPriceToNumber(price: string){
      if(price && price.includes('–')){
        return +price.split('–')[0].replace(',', '').replace('$', '');
      }
      else if (price){
        return +price.replace(',', '').replace('$', '');
      }
      else{
        return 0;
      }
    }
  }
  
  /**
   * Order from highest to lowest and convert to number
   * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  */
  public orderByRatingHighestToLowest(a:string, b:string){
    const itemA = +a.replace(',','');
    const itemB = +b.replace(',','');
    return itemA > itemB ? 1 : -1;
  }

  orderByShuffle(){
    const itemA = Math.random();
    const itemB = Math.random();
    return itemA > itemB ? 1 : -1;
  }

  orderbyHighDiscount(a:string, b:string) {
    const priceA = a.replace('-', '').replace('%', '');
    const priceB = b.replace('-', '').replace('%', '');
    
    const itemA = formatPriceToNumber(priceA);
    const itemB = formatPriceToNumber(priceB);
    return itemA > itemB ? 1 : -1;

    /**
     * Convert string offer price into number
     * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
    */
    function formatPriceToNumber(price: string) {
      if (price && price.includes('–')) {
        return +price.split('–')[0].replace(',', '').replace('$', '');
      }
      else if (price) {
        return +price.replace(',', '').replace('$', '');
      }
      else {
        return 999999; //return highest price
      }
    }
  }

  orderByReserved(a: string, b: string){
    return +a > +b ? 1 : -1;
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environment';
import { OffersAPI } from '@models/offers';

// models
import { OrderPipeConfig } from '@models/orderBy';

// services
import { OrderByService } from '@services/order-by.service';

// 3-party 
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-offer-card-container',
  templateUrl: './offer-card-container.component.html',
  styleUrls: ['./offer-card-container.component.css']
})
export class OfferCardContainerComponent implements OnInit {

  @Input('offers-param') Offers: OffersAPI[];
  @Input() displayDeparmentCards: boolean = true;

  // masonry infinite scroll
  itemsLimit = environment.itemsPerPage;
  currentMasonryItems: OffersAPI[] = [];
  tempMasonryItems: OffersAPI[] = [];
  
  // order by config
  orderByPipeConfig: OrderPipeConfig;
  currentExpression: string;
  currentReverse: boolean;
  currentCaseInsensitive: boolean;
  currentComparator: Function | undefined;

  constructor(
    private orderPipe: OrderPipe,
    private orderByService: OrderByService ) {

    this.currentExpression = this.orderByService.getCurrentOrderBy().config.expression;
    this.currentReverse = this.orderByService.getCurrentOrderBy().config.reverse;
    this.currentCaseInsensitive = this.orderByService.getCurrentOrderBy().config.caseInsensitive;
    this.currentComparator = this.orderByService.getCurrentOrderBy().config.comparator;
  }

  ngOnInit(): void {
    this.setListeners();
  }

  setListeners() {
    this.tempMasonryItems = this.orderPipe.transform(this.Offers, this.currentExpression, this.currentReverse, this.currentCaseInsensitive, this.currentComparator).slice(0, this.itemsLimit);
    this.currentMasonryItems = this.tempMasonryItems;
  }

  showMoreImages() {
    this.itemsLimit += environment.itemsPerPage;
    this.tempMasonryItems = this.orderPipe.transform(this.Offers, this.currentExpression, this.currentReverse, this.currentCaseInsensitive, this.currentComparator).slice(0, this.itemsLimit);
    this.currentMasonryItems = this.tempMasonryItems;
  }
}

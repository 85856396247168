import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hot-sale2022',
  templateUrl: './hot-sale2022.component.html',
  styleUrls: ['./hot-sale2022.component.css']
})
export class HotSale2022Component implements OnInit {

  @Input() referLink: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}

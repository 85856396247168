<div class="d-flex align-items-center justify-content-end">
    <button 
        *ngIf="currentSelectedOption.value === orderByType.shuffle"
        type="button" class="btn btn-secondary ms-1" (click)="shuffle()">
        <div class="d-flex justify-content-start align-items-center">
            <i class="fa-solid fa-shuffle me-1"></i>
            <span>Shuffle</span>
        </div>
    </button>
    <button class="d-flex align-items-center btn btn-secondary ms-1" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        <div class="a d-flex flex-row align-items-center">
            <span [innerHTML]="currentSelectedOption.iconTemplate" class="pe-2"></span>
            <div class="a d-flex flex-column align-items-start | lh-sm | text-truncate">
                <span>Ordenar por</span>
                <span class="a font-bold">{{ currentSelectedOption.name }}</span>
            </div>
            <i class="fa-solid fa-caret-down ps-2"></i>
        </div>
    </button>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-body custom-scroll">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="offcanvas-title lead" id="offcanvasExampleLabel">Ordenar por criterio</p>
            <button type="button" class="btn btn-outline-secondary btn-lg" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
        <ul class="list-group">
            <li class="list-group-item list-group-item-action hand-cursor" 
                data-bs-dismiss="offcanvas" aria-label="Close"
                [ngClass]="currentSelectedOption.name == option.name ? 'active' : ''" (click)="changeOrderBy(option)"
                *ngFor="let option of orderCriteriasOptions">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-column justify-content-start">
                        <div class="d-flex justify-content-start align-items-center">
                            <span [innerHTML]="option.iconTemplate" class="pe-1"></span>
                            <p class="lead fw-bold m-0">{{ option.name }}</p>
                        </div>
                        <span class="text-muted">
                           {{ option.hint }}
                        </span>
                    </div>
                    <div *ngIf="currentSelectedOption.name == option.name">
                        <i class="fa-solid fa-check"></i>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
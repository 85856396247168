// components
import { OffersByMarketingCampaingComponent } from '@components/offers-by-marketing-campaing/offers-by-marketing-campaing.component';
import { elBuenFin, hotSaleMarketingCampaing } from '@models/my-offers-by-marketing-campaing';


export const marketingCampaingRoutes = [
  {
    path: hotSaleMarketingCampaing.route,
    component: OffersByMarketingCampaingComponent,
    data: { animation: 'DepartmentTransition1' }
  },
  // {
  //   path: elBuenFin.route,
  //   component: OffersByMarketingCampaingComponent,
  //   data: { animation: 'DepartmentTransition1' }
  // },
]
// this class is based on the following example https://mdbootstrap.com/snippets/standard/mdbootstrap/2964350

import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-back-to-top',
  templateUrl: './my-back-to-top.component.html',
  styleUrls: ['./my-back-to-top.component.css']
})
export class MyBackToTopComponent implements OnInit {

  // example to use event listener http://blog.enriqueoriol.com/2018/06/evento-scroll-angular.html
  @HostListener("window:scroll", ['$event'])
  doSomethingOnScroll($event: Event) {
    this.scrollFunction();
  }

  constructor() { }

  ngOnInit(): void {
  }

  scrollFunction() {
    const myButton = document.getElementById("btn-back-to-top");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      myButton.style.display = "block";
    } else {
      myButton.style.display = "none";
    }
  }

  backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


}

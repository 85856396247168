import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { OffersByMarketingCampaingComponent } from '../offers-by-marketing-campaing.component';

// modules
import { OrderByCriteriaOffcanvasModule } from '@components/order-by-criteria-offcanvas/module/order-by-criteria-offcanvas.module';
import { DeparmentScrollingComponentModule } from '@components/deparment-scrolling-component/module/deparment-scrolling-component.module';
import { MyBackToTopModule } from '@components/my-back-to-top/module/my-back-to-top.module';
import { OfferCardContainerModule } from '@components/shared/offer-card-container/module/offer-card-container.module';
import { PlaceholderContainerModule } from '@components/shared/placeholder/placeholder-container/module/placeholder-container.module';
import { BannerTopModule } from '@components/banner-top/module/banner-top.module';
import { TypeaheadOffcanvasModule } from '@components/typeahead-offcanvas/module/typeahead-offcanvas.module';
import { JsonLdModule } from '@components/json-ld/module/json-ld.module';

// pipes
import { PipesModule } from '@pipes/module/pipes.module';


@NgModule({
  declarations: [
    OffersByMarketingCampaingComponent
  ],
  imports: [
    CommonModule,
    OrderByCriteriaOffcanvasModule,
    PlaceholderContainerModule,
    MyBackToTopModule,
    PipesModule,
    OfferCardContainerModule,
    DeparmentScrollingComponentModule,
    BannerTopModule,
    JsonLdModule,
    TypeaheadOffcanvasModule
  ]
})
export class OffersByMarketingCampaingModule { }

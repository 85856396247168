
<div *ngIf="Offers">
    <!-- don't remove index from *ngFor because is used for card's component -->

    <div class="container-fluid">
        <div class="row row-cols-2 row-cols-md-4 row-cols-lg-5 mx-lg-n5 g-1 g-md-2 g-lg-2">
            <ng-container *ngFor="let item of currentMasonryItems; let i = index">
                <div class="col" app-offer-card [offer-param]="item" [index-param]="i"></div>
                <div *ngIf="displayDeparmentCards && i % 2 === 0" class="col" app-departament-card [index]="i"></div>
            </ng-container>
        </div>
    </div>
    
    <!-- don't remove index from *ngFor because is used for card's component -->

    <div *ngIf="itemsLimit < Offers.length"  
        class="d-flex justify-content-center animated fadeIn">
        <button (click)="showMoreImages()" 
            type="button" class="btn btn-outline-secondary text-white btn-lg mt-3 p-2 px-3">
            <div class="d-flex justify-content-center align-items-center">
                Mostrar más
                <i class="fa-solid fa-circle-chevron-down ms-1"></i>
            </div>
        </button>
    </div>

    <div *ngIf="itemsLimit >= Offers.length" 
        class="d-flex flex-column justify-content-center align-items-center animated fadeIn">
        <p class="lead m-0 mt-2 mt-md-4">
            Ya viste todas las Ofertas
        </p>
        <i class="fa-solid fa-face-grin-tongue-wink mt-2 fa-2x"></i>
    </div>
</div>
<app-toast></app-toast>
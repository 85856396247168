import { 
  Component, ElementRef, HostListener, Input, OnDestroy, ViewChild 
} from '@angular/core';

// environment
import { environment } from '@environment';

// interfaces
import { OffersAPI } from '@models/offers';
import { offersByDepartament } from '@models/my-offers-by-departament';
import { OffersRestApiService } from '@services/offers-rest-api.service';

// rxjs
import { Subscription } from 'rxjs';

// 3-party
import typeahead from 'typeahead-standalone';

// bootstrap
declare var bootstrap;

@Component({
  selector: 'app-typeahead-offcanvas',
  templateUrl: './typeahead-offcanvas.component.html',
  styleUrls: ['./typeahead-offcanvas.component.css']
})
export class TypeaheadOffcanvasComponent implements OnDestroy {


  // example https://medium.com/dottech/por-qu%C3%A9-es-mejor-evitar-addeventlistener-en-angular-6469db587260
  @HostListener('show.bs.offcanvas')
  onShowOffcanvas() {
    this.getOffersData();
    this.setFocusIntoSearchField();
    window.location.hash = this.currentComponentUrlHash;
  }

  @HostListener('shown.bs.offcanvas')
  onShownOffcanvas() {
    this.setFocusIntoSearchField();
  }

  @HostListener('hide.bs.offcanvas')
  onHideOffcanvas() {
    this.resetTypeaheadInput();
    // remove hash from url https://stackoverflow.com/a/5298684/7724301
    history.pushState("", document.title, window.location.pathname + window.location.search);
  }

  @HostListener('window:hashchange', ['$event'])
  onHashChange() {
    if (window.location.hash != `#${this.currentComponentUrlHash}`) {
      this.closeOffcanvas();
    }
  }

  @ViewChild('myTypeaheadInput') typeaheadInput: ElementRef;
  @Input() dataToSearchIn: OffersAPI[];
  myOffersByDepartament = offersByDepartament.sort(() => Math.random() - 0.5);
  suscription: Subscription;
  currentComponentUrlHash = 'search-offers'; 

  constructor(
    private offersRestApi: OffersRestApiService
  ) { }

  ngOnDestroy(): void {
    if (this.suscription) this.suscription.unsubscribe();
  }


  getOffersData() {
    this.initTypeahead(this.dataToSearchIn);
  }

  setFocusIntoSearchField() {
    const typeaheadElId = "exampleFormControlInput1";
    const $inputSel = document.getElementById(typeaheadElId) as HTMLInputElement;
    $inputSel.focus();
  }
  
  resetTypeaheadInput() {
    this.typeaheadInput.nativeElement.value = '';
  }

  initTypeahead(data: OffersAPI[]) {
    const typeaheadElId = "exampleFormControlInput1";
    const inputElement = document.getElementById(typeaheadElId) as HTMLInputElement;
    const currentData: any = data;

    const instance = typeahead({
      input: inputElement,
      source: {
        local: currentData,
        identifier: 'title'
      },
      minLength: 2,
      debounceRemote: 330,
      highlight: true,
      diacritics: true,
      hint: false,
      limit: 13,
      preventSubmit: true,
      templates: {
        suggestion: (item) => {
          const template = `
          <div class="d-flex align-items-center stretched-link p-1" onclick="window.open('${item.url}')">
            <div class="typeahead-item-preview">
             <img src="${item.image}" alt="${item.title}" class="typeahead-item-preview me-1">
            </div>
            <span class="elipsis-2">${item.title}</span>
          </div>
        `;
          return template;
        },
        notFound: (resultSet) => {
          const template = `
          <div class="d-flex flex-column align-items-center justify-content-center">
          <span class="my-2">Aun no hemos encontrado resultados</span>
            <button class="btn btn-primary my-2" type="button" onclick="window.open('${environment.referLink}')">
                <div class="d-flex align-items-center">
                    <i class="fas fa-search pe-1"></i>
                    Buscar entre todas las Ofertas
                </div>
            </button>
          </div>
        `;
          return template;
        },
        loader: () => {
          const template = `
          <div class="d-flex justify-content-center py-2">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>`;
          return template;
        },
      },
    });
  }

  closeOffcanvas() {
    const myOffcanvas = bootstrap.Offcanvas.getOrCreateInstance(document.getElementById('offcanvasTypeahead'));
    myOffcanvas.hide();
  }
}


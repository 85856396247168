<app-typeahead-offcanvas [dataToSearchIn]="currentMasonryItems" ></app-typeahead-offcanvas>
<!-- ld+json seo script -->
<app-json-ld *ngIf="showBodyContent" [arr]="currentMasonryItems" [title]="currentPageTitle" [description]="currentPageDescription"></app-json-ld>
<!-- ld+json seo script -->

<div *ngIf="showTopContent">
    <div class="a mt-2 mt-sm-3 for-spacing"></div>
    <div *ngIf="showBodyContent && !applyHiddenStyleOfferListContent" class="container-fluid my-2">
        <div class="row">
            <div class="d-flex justify-content-end align-items-center">
                <button type="button" class="d-none d-sm-block | btn me-1" (click)="moveScrollNext()" [disabled]="scrollStarted"
                    [ngClass]="[scrollStarted ? 'btn-outline-secondary' : 'btn-secondary']">
                    <i class="fa-solid fa-chevron-left"></i>
                </button>
                <!-- hidden on mobile -->
                <div #scrollableDiv app-deparment-scrolling-component (scroll)="onScroll($event)"
                    id="department-scroll" class="a d-flex flex-nowrap | scrolling-wrapper| custom-scroll | snap-type-x"
                    style="overflow-x: auto; padding-bottom: 3px;" [extraClass]="'d-none d-sm-block'" [isDepartment]="true">
                </div>
                <button type="button" class="d-none d-sm-block | btn ms-1" (click)="moveScrollBack()" [disabled]="scrollEnded"
                    [ngClass]="[scrollEnded ? 'btn-outline-secondary' : 'btn-secondary']">
                    <i class="fa-solid fa-chevron-right"></i>
                </button>
                <app-order-by-criteria-offcanvas (onChangedOrderByCriteria)="setCurrentOrderByCriteria($event)"
                    [currentPage]="currentPage">
                </app-order-by-criteria-offcanvas>
            </div>
            <div class="a">
                <!-- visible only on desktop -->
                <div app-deparment-scrolling-component
                    class="a d-flex flex-nowrap | scrolling-wrapper | custom-scroll | snap-type-x"
                    style="overflow-x: auto; padding-bottom: 3px;" [extraClass]="'d-block d-sm-none mt-2'">
                </div>
            </div>
        </div>
    </div>
</div>

<app-placeholder-container></app-placeholder-container>

<div *ngIf="showBodyContent" class="offer-list main-container">
    <app-offer-card-container 
        [offers-param]="currentMasonryItems" [displayDeparmentCards]="false"
        [ngClass]="hiddenOfferListContent ? 'visibility:hidden;' : 'visibility:visible;'">
    </app-offer-card-container>
</div>

<div *ngIf="showBodyContent" class="container">
    <div class="row">
        <div class="col-md-12 mb-5 mt-2">
            <div class="a d-flex justify-content-end align-items-center">
                <p class="lead mb-0 updated-ago-info">
                    <i class="fa-regular fa-calendar me-1"></i>
                    Actualizado {{ currentMasonryItems[0].date | updatedAgo | lowercase }}
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="noResults" class="d-flex flex-column align-items-center justify-content-center mt-5 pt-5">
    <p class="lead display-6 mt-3 mx-3 text-center">
        Por el momento no tenemos más ofertas para mostrar en el departamento {{currentNoResultsDepartment?.displayName}}
    </p>
    <button type="button" class="btn btn-outline-light" (click)="goBack()">
        <i class="fa-solid fa-chevron-left"></i>
        Regresar a la página anterior
    </button>
</div>

<app-my-back-to-top></app-my-back-to-top>
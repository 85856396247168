import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// interface
import { DepartmentInternalDetails } from '@models/departament';

// environment
import { environment } from '@environment';

@Component({
  selector: '[app-deparment-scrolling-component]',
  templateUrl: './deparment-scrolling-component.component.html',
  styleUrls: ['./deparment-scrolling-component.component.css']
})
export class DeparmentScrollingComponentComponent implements OnInit {

  @Input() extraClass: string;
  @Input() isDepartment: boolean = false;
  myAvailableDepartments: DepartmentInternalDetails[] = environment.availableDepartments;
  currentRouterUrl: string;

  constructor(
    private router: Router
  ) { 
    this.currentRouterUrl = this.router.url;
  }

  ngOnInit(): void {
  }

}

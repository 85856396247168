import { Component, OnInit } from '@angular/core';

// environment
import { environment } from '@environment';

// services
import { ToastService } from 'app/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  title: string;
  message: string;
  timestamp: string;
  isVisible = false;

  constructor(
    private toastService: ToastService
  ) {
    
  }

  ngOnInit(): void {
    this.toastService.toastState = this;
  }

  showToast(message: string, title?: string,) {
    const duration = environment.toastDuration || 3001;

    if (this.isVisible) {
      this.hideToast();
    }
    
    this.title = title;
    this.message = message;
    this.timestamp = new Date().toLocaleTimeString();
    this.isVisible = true;

    setTimeout(() => {
      this.isVisible = false;
    }, duration);
  }

  hideToast() {
    this.isVisible = false;
  }

}

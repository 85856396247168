import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { PlaceholderContainerComponent } from '../placeholder-container.component';
import { CompletePlaceholderComponent } from '../../complete-placeholder/complete-placeholder.component';
import { PartialPlaceholderComponent } from '../../partial-placeholder/partial-placeholder.component';

@NgModule({
  declarations: [
    PlaceholderContainerComponent,
    CompletePlaceholderComponent,
    PartialPlaceholderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PlaceholderContainerComponent
  ]
})
export class PlaceholderContainerModule { }

import { Injectable } from '@angular/core';

// 3-party
import { NgNavigatorShareService } from 'ng-navigator-share';

// enviroment
import { environment } from '@environment';

// services
import { ToastService } from 'app/toast.service';


@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(
    private toastService: ToastService,
    private ngShare: NgNavigatorShareService
  ) { }

  share(text:string, url:string) {
    const toShare = {
      title: `${text} en Amazon México`,
      text: `${text} en Amazon México`,
      url: url
    };
    const offerSharedMessage: string = 'Se compartió la Oferta';

    this.ngShare
    .share(toShare)
    .then( res =>{
      this.toastService.showToastNotify(offerSharedMessage);
    })
  }

  // @doc Solution from here https://stackoverflow.com/a/30512485/7724301
  goTo(url: string) {
    let currentUrl = environment.referLink;
    if(url){
      currentUrl = url;
    }
    const urlNavigator = window.open(currentUrl, "_blank");
    urlNavigator.location;
  }
}


import { Departament, DepartmentInternalDetails } from "./departament";

// date-fns
import { format } from 'date-fns';
import { es } from "date-fns/locale";

// 1. Offers by department computers and accessories
export const computersAndAccessoriesDepartment: DepartmentInternalDetails = {
  displayName: 'PCs y Accesorios',
  route: 'pcs-y-accesorios',
  title: `💻 Ofertas en PCs y 🖱️ Accesorios ➥ 【${getCurrentDate()}】`,
  description: '🥇 ¡Oferta limitada en PCs y Accesorios! Obtén un rendimiento óptimo con precios increíbles. → ¡Haz tu pedido ahora! 💖',
  iconTemplate: '<i class="fa-solid fa-computer"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Computers_white._CB468586682_.png',
}

// 2. Offers by department electronic accessories
export const electronicAccessoriesDepartment: DepartmentInternalDetails = {
  displayName: 'Accesorios Electrónicos',
  route: 'accesorios-electronicos',
  title: `🔥 Ofertas del Día 🔥 en Accesorios Electrónicos ➥ 【${getCurrentDate()}】`,
  description: 'Ofertas en Accesorios Electrónicos! ➤ Promociones, ofertas y descuentos increíbles en los mejores accesorios electrónicos. ✔️¡Aprovecha y ahorra! 💖',
  iconTemplate: '<i class="fa-solid fa-headphones"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Electronics_white._CB468586680_.png',
}

// 3. Offers by department games and toys
export const gamesAndToysDepartment: DepartmentInternalDetails = {
  displayName: 'Juegos y Juguetes',
  route: 'juegos-juguetes',
  title: `🛴¡Compra juegos 🛹 y juguetes 🚲 en descuento el día ${getCurrentDate()} y disfruta al máximo!`,
  description: '🥇 ¡Descuentos en juegos y juguetes! Encuentra la mejor diversión a precios increíbles. ¡Haz tu compra ahora! 💖',
  iconTemplate: '<i class="fa-solid fa-car-side"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Toys_white._CB468586676_.png',
}

// 4. Offers by department beauty
export const beautyDepartment: DepartmentInternalDetails = {
  displayName: 'Belleza',
  route: 'belleza',
  title: `🥇¡Deslumbra con nuestras Promociones del ${getCurrentDate()} en el departamente de Belleza! 😍`,
  description: '🤩 ¡Promoción de belleza! Obtén productos de cuidado personal a precios increíbles y realza tu belleza natural. 💖😍',
  iconTemplate: '<i class="fa-solid fa-spa"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Beauty_white._CB468586681_.png',
}

// 5.  Offers by department fashion
export const fashionDepartment: DepartmentInternalDetails = {
  displayName: 'Moda',
  route: 'moda',
  title: `👗👕👠 Actualiza tu estilo con nuestras ofertas en moda! 【${getCurrentDate()}】🥇😍`,
  description: '🤩 Con nuestras ofertas en Moda! Encuentra los looks más modernos a precios irresistibles y luce espectacular en todo momento. 💃🕺💖',
  iconTemplate: '<i class="fa-solid fa-socks"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Fashion_white.png'
}

// 6. Offers by department home and kitchen
export const homeAndKitchenDepartment: DepartmentInternalDetails = {
  displayName: 'Hogar y Cocina',
  route: 'hogar-cocina',
  title: `🏠🍴 ¡Ofertas de hogar y cocina: Haz de tu hogar un lugar acogedor! ${getCurrentDate()} 😍`,
  description: '🤩 Encuentra Ofertas de hogar y cocina con todo lo que necesitas para renovar tu espacio y preparar deliciosas comidas a precios increíbles. 😍',
  iconTemplate: '<i class="fa-solid fa-sink"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Kitchen_white._CB468586676_.png',
}

// 7. Offers by department outdoor sports
export const outdoorSportsDepartment: DepartmentInternalDetails = {
  displayName: 'Deportes y Aire libre',
  route: 'deportes-aire-libre',
  title: `✓🥇 Las mejores promociones el día ${getCurrentDate()} en Deportes y Aire Libre! 😍`,
  description: '🤩🏋️‍♂️🏃‍♀️🚴‍♂️ ✓ Deportes y aire libre: Encuentra todo lo que necesitas para mantenerte activo y disfrutar al aire libre a precios increíbles 😍',
  iconTemplate: '<i class="fa-solid fa-dumbbell"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Sports_white._CB468586678_.png'
}

// 8. Offers by department tv and video
export const tvAndVideoDepartment: DepartmentInternalDetails = {
  displayName: 'TV y Video',
  route: 'tv-video',
  title: `➨🥇📺🎬 ¡No te pierdas las mejores ofertas en TV y video! ${getCurrentDate()}`,
  description: '🤩 Ofertas en TV y video! ✅ Disfruta de tus películas y programas favoritos con la mejor calidad de imagen y sonido a precios increíbles. 😍',
  iconTemplate: '<i class="fa-solid fa-tv"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/img17/events/blackfriday/bfcm_landing_page_faceout_tvs._CB512071466_.jpg',
}

// 9. Offers by department books
export const booksDepartment: DepartmentInternalDetails = {
  displayName: 'Libros',
  route: 'libros',
  title: `➨🥇📚📖 ¡Descuentos Imperdibles en Libros! 😍 Ofertas del Día ${getCurrentDate()}`,
  description: '✅ 📖 ¡Descuentos en libros! Amplía tu colección de lecturas con los mejores títulos a precios increíbles y sumérgete en nuevas aventuras. 🤩📚',
  iconTemplate: '<i class="fa-solid fa-book"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Books_white._CB468586681_.png',
}

// 10. Offers by department cellphoners and accessories
export const cellphonesAndAccessoriesDepartment: DepartmentInternalDetails = {
  displayName: 'Celulares y Accesorios',
  route: 'celulares-accesorios',
  title: `✓🥇📱💻 Descuentos en Celulares y Accesorios! 😍 Ofertas del Día ${getCurrentDate()}`,
  description: '✅ 📱💻 ¡Consigue descuentos en celulares y accesorios! Encuentra lo mejor en tecnología a precios increíbles y mantente conectado en todo momento. 🌐💬',
  iconTemplate: '<i class="fa-solid fa-mobile-screen-button"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Cell_white._CB468586680_.png',
} 

// 11. Offers by department personal care
export const personalCareDepartment: DepartmentInternalDetails = {
  displayName: 'Cuidado Personal',
  route: 'cuidado-personal',
  title: `✓🔥🧖‍♀️🧖‍♂️ ¡Ofertas irresistibles en Cuidado Personal: Renueva tu rutina! 😍 Ofertas del Día ${getCurrentDate()}`,
  description: '✅ Encuentra Ofertas en Cuidado Personal de los mejores productos para lucir radiante y sentirte increíble a precios irresisitibles. 💆‍♀️💆‍♂️💖',
  iconTemplate: '<i class="fa-solid fa-mortar-pestle"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/HPC_white._CB468586682_.png',
}

// 12. Offers by department home improvements
export const homeImprovementsDepartment: DepartmentInternalDetails = {
  displayName: 'Mejoras del Hogar',
  route: 'mejoras-del-hogar',
  title: `✓🔥 Las mejores Ofertas para Mejoras del Hogar 😍 Ofertas del Día ${getCurrentDate()}`,
  description: '✅🔨🏡 ¡Con nuestras ofertas en mejoras del hogar! Encuentra todo lo que necesitas para renovar y embellecer tu espacio a precios increíbles. 🤩🏠💖',
  iconTemplate: '<i class="fa-solid fa-hammer"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/rsz_homeimprovement.png',
}

// 13. Offers by department videogames
export const videoGamesDepartment: DepartmentInternalDetails = { 
  displayName: 'Videojuegos',
  route: 'videojuegos',
  title: `➜🥇🕹️🎮 ¡Descubre los Videojuegos en oferta más vendidos del día ${getCurrentDate()}!😍`,
  description: '✅ ¡Saca tu lado gamer con nuestras ofertas en videojuegos! Encuentra los títulos más populares y disfruta de horas de diversión a precios increíbles. 🤩👾💖',
  iconTemplate: '<i class="fa-solid fa-gamepad"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Video_white._CB468586673_.png',
}

// 14. Offers by department cameras and photography
export const camerasAndPhotographyDepartment: DepartmentInternalDetails = {
  displayName: 'Cámaras y Fotografía',
  route: 'camaras-fotografia',
  title: `📸🌄➜ ¡Mejores Ofertas en Cámaras y Fotografía! ¦ Ofertas del Día ${getCurrentDate()}`,
  description: '📸🌄 ✅ Encuentra ofertas en cámaras y fotografía con la mejor tecnología para plasmar tus recuerdos en imágenes a precios increíbles. 🤩📷',
  iconTemplate: '<i class="fa-solid fa-camera-retro"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Camera_white._CB468586680_._SY100_SX600_.png'
}

// 15. Offers by department bags and suitcases
export const bagsAndSuitcasesDepartment: DepartmentInternalDetails = {
  displayName: 'Bolsas y Maletas',
  route: 'bolsas-y-maletas',
  title: `【Ofertas del día】en Bolsas, Maletas y Accesorios de Viaje ➩ ${getCurrentDate()}`,
  description: 'Ofertas en Bolsas y Maletas: ➜ Encuentra el equipaje perfecto para tus aventuras con promociones increíbles y viaja con comodidad y estilo. 🌎✈️',
  iconTemplate: '<i class="fa-solid fa-suitcase-rolling"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Travel_white._CB468586676_.png',
}

// 16. Offers by department furnitures
export const furnituresDepartment: DepartmentInternalDetails = {
  displayName: 'Muebles',
  route: 'muebles',
  title: `Muebles Modernos para Hogar y oficina✔️【Ofertas Del Día ${getCurrentDate()}】`,
  description: '¡Ofertas del Día en Muebles para tu Hogar de Ensueño! ✔️ Descubre descuentos y diseños irresistibles para cada espacio: desde el baño hasta la cocina, pasando por la recámara, sala y hasta la oficina. 🤩😍',
  iconTemplate: '<i class="fa-solid fa-couch"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/img22/Events/PrimeCCHFK/Q2_2022_PrimeCC_HFKDealEvent_category-bubble_sobe_320x320_3_003.png',
}

// 17. Offers by department baby
export const babyDepartment: DepartmentInternalDetails = {
  displayName: 'Bebé',
  route: 'bebe',
  title: `【Ofertas】➨ en Productos para Bebé 🔥Ofertas del Día 🔥➩ ${getCurrentDate()}`,
  description: 'Ofertas en Productos para Bebé! ➤ Encuentra la mejor oferta en artículos para el cuidado y bienestar de tu pequeñito a precios increíbles. 🤱👶 ✔️¡Aprovecha y ahorra! 💖',
  iconTemplate: '<i class="fa-solid fa-baby-carriage"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Parents_white._CB468586676_.png',
}

// 18. Offers by department pets
export const petsDepartment: DepartmentInternalDetails = {
  displayName: 'Mascotas',
  route: 'mascotas',
  title: `【Ofertas y Descuentos 】✅🐾🐶en Mascotas y Accesorios 🔥Ofertas del Día 🔥➩ ${getCurrentDate()}`,
  description: '¡Ofertas en productos para Mascotas! ➜🤩 ¡Consiente a tu mejor amigo, ➨ Encuentra todo lo que necesitas para el cuidado y bienestar de tu mascota a precios increíbles. 🐱🐶',
  iconTemplate: '<i class="fa-solid fa-cat"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Pets_white._CB468586679_.png',
}

// 19. Offers by department office
export const officeDepartment: DepartmentInternalDetails = {
  displayName: 'Oficina y Papelería',
  route: 'oficina-y-papeleria',
  title: `¡Ofertas en artículos de Oficina y Papelería!  ✅📎📚  Ofertas del Día 🔥➩ ${getCurrentDate()}`,
  description: '【Ofertas】 en artículos de Oficina y Papelería ➜🤩 Compra todo lo que necesitas para ser más productivo. ✓ Aprovecha descuentos increíbles y trabaja con comodidad y estilo. 🤩📌',
  iconTemplate: '<i class="fa-solid fa-pen-clip"></i>',
  imagePreview: 'https://m.media-amazon.com/images/G/01/goldbox/images/faceout/Office_white._CB468586679_.png',
}

export const offersByDepartament: Departament[] = [
  {
    name: computersAndAccessoriesDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/81OiT2-NoeL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/413yS35OeEL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41UDhxGrqbL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51Vph-vspLL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41Tr6d7jFwL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31XD05pkVtL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41O+HV+rWIL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41reg2GJ0pL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51UOz+yhu4L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51JAu8iLoZL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: computersAndAccessoriesDepartment.iconTemplate,
    url: 'https://amzn.to/44JK4HQ',
    hint: 'Mejora tu experiencia de juego con los accesorios de alta tecnología'
  },
  {
    name: electronicAccessoriesDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/51kxIEmO+ZL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41+sgEU1ImL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51qoWjZ0X3L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51GuwTAPkIL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41J41RCnbUL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/21KozAdiVXL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31vwahOVoBL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51Tu7HHWfjL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: electronicAccessoriesDepartment.iconTemplate,
    url: 'https://amzn.to/42mHoON',
    hint: 'Sorprende a tus amigos con los accesorios electrónicos más cool'
  },
  {
    name: gamesAndToysDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/71ePgDTj5ML._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/414Xkkc8b1L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41QC4Fm23tL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/91TygH6SY9L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/91sxB57AmaL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81gjwZb4EcL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71X0eh9WX5L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/712p6tQ9pzS._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71voIS1graL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71GDZ47-F8L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81yRF0q-siL._AC_UF515,515_FMjpg_._AA515_.jpg',
    ],
    iconTemplate: gamesAndToysDepartment.iconTemplate,
    url: 'https://amzn.to/41ogRiA',
    hint: 'Crea tus propias aventuras con los juguetes más innovadores'
  },
  {
    name: beautyDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/41kRk9kZSXL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51OS-baetdL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71GIRogtyNL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61yY-2l7Q8L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81vPXZj0cJL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41-qJ+YZ5vL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51eLnkeoF8L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41OHUst9+bL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: beautyDepartment.iconTemplate,
    url: 'https://amzn.to/3nONl86',
    hint: 'Luce hermosa sin importar la ocasión con nuestros productos de belleza top'
  },
  {
    name: fashionDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/81naTg9Yn3L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51wAhS27w9L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41zrt9avTJL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51F3Q63wtuL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/417vsVyBdkL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/319gPl+kUfL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/515zUdHCynL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: fashionDepartment.iconTemplate,
    url: 'https://amzn.to/3HZ32k2',
    hint: 'Actualiza tu armario con las tendencias de moda más calientes'
  },
  {
    name: homeAndKitchenDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/41TrsScEZlL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41k7EnRX62L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51V-lQvKtGL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51Ee1w4wUtL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/5157Cj0S74L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51xPL861DdL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: homeAndKitchenDepartment.iconTemplate,
    url: 'https://amzn.to/3nN1X7X',
    hint: 'Cocina como un chef con nuestros productos de alta calidad para la cocina'
  },
  {
    name: outdoorSportsDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/51KBt5eBlWL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/414esxe6KvL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/317hT0hzOpL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41TT8Tp9VyL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41LfCA0JSJL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61K4-ydpwlL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41AQupXjmYL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41QcGzS2CWL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51vLE5uaxPL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: outdoorSportsDepartment.iconTemplate,
    url: 'https://amzn.to/3VNYJOa',
    hint: 'Sé el mejor en la cancha con nuestros productos deportivos y para aire libre'
  },
  {
    name: tvAndVideoDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/71MuqjA25JL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61bY5NZZmZL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/618hPPx7M3L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81lKxcgnn4L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61-JgGHkJwL._AC_UF515,515_FMjpg_._AA515_.jpg ',
      'https://m.media-amazon.com/images/I/41Wmi3tZ5TL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41fFirtOwwL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/411U4YSpDbL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: tvAndVideoDepartment.iconTemplate,
    url: 'https://amzn.to/3VOgRqY',
    hint: 'Experimenta la vida en alta definición con nuestros televisores y sistemas de video'
  },
  {
    name: booksDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/41T0C+rM3AL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41B0uVOmkAS._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51QrRtC-8NL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61+tBpkhJRL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41CoiygEzxL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81dxFCnAp0L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/91NW7CBaVwL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41XluB8LxmL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81YqcrdEnkL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: booksDepartment.iconTemplate,
    url: 'https://amzn.to/3NZKnbs',
    hint: 'Vive la experiencia de la lectura con nuestros libros de alta calidad'
  },
  {
    name: cellphonesAndAccessoriesDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/613lZoCcW+L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41-jgCVeISL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/21r8JZRB5VL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31kgjkAoFpL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/411dLakramL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51Tu7HHWfjL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41o5CHO3OaL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/415UWanqykL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/4199b6KYGcL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31bsvIvrg3L._AC_UF515,515_FMjpg_._AA515_.jpg'

    ],
    iconTemplate: cellphonesAndAccessoriesDepartment.iconTemplate,
    url: 'https://amzn.to/3nK7lsz',
    hint: 'Mantente conectado con el mundo con nuestros teléfonos celulares y accesorios'
  },
  {
    name: personalCareDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/71-HYlEU2+L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41BhVCvVM2L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51XB10BjK2L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/3126dczoF8L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41v6-JyvdOL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51ex03u65iL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: personalCareDepartment.iconTemplate,
    url: 'https://amzn.to/3NWxUVX',
    hint: 'Sé tu mejor versión con nuestros productos para el cuidado personal'
  },
  {
    name: homeImprovementsDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/51trV5g1yLS._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41XV1LEZ0vL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51CivJ5StGL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/611An0zFQUL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51px2xCNDdL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51RJeX7SjgL._AC_UF515,515_FMjpg_._AA515_.jpg',
    ],
    iconTemplate: homeImprovementsDepartment.iconTemplate,
    url: 'https://amzn.to/3LIOAh2',
    hint: 'Transforma tu hogar en tu oasis con nuestros productos para mejoras del hogar'
  },
  {
    name: videoGamesDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/41uQkUCwY-L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81s9R6b6KPL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81AmbNg+ueL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31CSkaMJoIL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41zosv66JrL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41sAu5WvQ6L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41jYgeYfHPL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/515x+7waS6L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41stcAN9G0L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41vpsXQopxL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71ogaAK8DGL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71NyRR72fWL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61SUJDrCTLL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71AKO+U6F6L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/8181vyG8OxL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71sRFdFgmHS._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/916jCHrzppL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81sZSJqEs0L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/91mQk5ZwbWL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/A1dXfW1yPNL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51lLzimS3pL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/717e9kcD95L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/714eNHubwSL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61gimpyy0UL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51npZvTjxPL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61ODFzyRtcL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81q6wTPqWJL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/612ZPI8Su3L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61y1CJDjQCL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61DnG7ZNwAL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81HOOHXyElL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81r8f2YyTxL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/912QhLQCPzL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61eYoSqkHnL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71NyRR72fWL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/719S1-Te9fS._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81rEvFkMYNL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61+nWgbb29L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61rnJLa6OfL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81QWa2SdU-L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51sBt06T-2L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71fwUD0JaFL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41dfkazFj9L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/916RmERQSGL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: videoGamesDepartment.iconTemplate,
    url: 'https://amzn.to/3NWEvzL',
    hint: 'Vive la aventura y la emoción con los videojuegos más nuevos'
  },
  {
    name: camerasAndPhotographyDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/61Jwof4vZnL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31Rf2eFTBwL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/21aJTLV0s7L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41SzGIo3eGL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/4115ZvuYgyL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/412PuH2cCKL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41Szmt-axWL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41es0L-jwkL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: camerasAndPhotographyDepartment.iconTemplate,
    url: 'https://amzn.to/3puMjhS',
  },
  {
    name: bagsAndSuitcasesDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/91Z4QvTewAL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/514V9UOhofL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41ZG2ykggPL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51g0ozJvX7L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61PVtXT2flL._AC_UF515,515_FMjpg_._AA515_.jpg',
    ],
    iconTemplate: bagsAndSuitcasesDepartment.iconTemplate,
    url: 'https://amzn.to/41nfVv1',
  },
  {
    name: 'Bebé',
    images: [
      'https://m.media-amazon.com/images/I/91HuntPlNGL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31QlIMDsCEL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41yqCf1tMPL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41UKvRA7qGL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/512EChPIyQL._AC_UF515,515_FMjpg_._AA515_.jpg',
    ],
    iconTemplate: '<i class="fa-solid fa-baby-carriage"></i>',
    url: 'https://amzn.to/3o0Kzwt',
  },
  {
    name: 'Mascotas',
    images: [
      'https://m.media-amazon.com/images/I/81Vt-NMX65L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61wE2R9hEmL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81LzdVmnGGL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/412EUmH4lJL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41fF0rqKYBL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: '<i class="fa-solid fa-cat"></i>',
    url: 'https://amzn.to/3VWZ6pt',
  },
  {
    name: 'Oficina y Papelería',
    images: [
      'https://m.media-amazon.com/images/I/71-Yy9DvksL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31bCPetuToL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41S7SJqax0L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/413ALHwOizL._AC_UF515,515_FMjpg_._AA515_.jpg',

    ],
    iconTemplate: '<i class="fa-solid fa-pen-clip"></i>',
    url: 'https://amzn.to/41BSgY0',
  },
  {
    name: 'Smart Watches y relojes',
    images: [
      'https://m.media-amazon.com/images/I/71gg8mPlAuL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41bq7MG6gaL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/413sne3qmyL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31sQNs5DDiL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31YJvKRA84L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41Fogs7sdyL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/518fpH8mt5L._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: '<i class="fa-solid fa-stopwatch"></i>',
    url: 'https://amzn.to/41xNyu2',
  },
  {
    name: 'Alimentos y Bebidas',
    images: [
      'https://m.media-amazon.com/images/I/617ipYtj0WL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51kYxEQ8JKL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61pkCpJ1uML._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71Z934cHPmL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71x0BYqNFWL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71lXRO4sdgL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81sjyiN+l+S._AC_UF515,515_FMjpg_._AA515_.jpg',

    ],
    iconTemplate: '<i class="fa-solid fa-burger"></i>',
    url: 'https://amzn.to/41ufAqc',
  },
  {
    name: 'Automotriz',
    images: [
      'https://m.media-amazon.com/images/I/619eo8TigkL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61wCvqfc5iL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61AYXfH+aZL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71xtQErOawL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41iuDiiGYmL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41VhJj+oM7L._AC_UF515,515_FMjpg_._AA515_.jpg', 
      'https://m.media-amazon.com/images/I/41Expb-I1CL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: '<i class="fa-solid fa-car-battery"></i>',
    url: 'https://amzn.to/42OiWpq',
  },
  {
    name: 'Cervezas, Vinos y Licores',
    images: [
      'https://m.media-amazon.com/images/I/6173217PfHL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/616ZldRVhNL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71VrWvbYg0L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/61AR6fFjh7L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41aCswx5dqL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31p8zwfTBjL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41EkTGzmLiL._AC_UF515,515_FMjpg_._AA515_.jpg',
    ],
    iconTemplate: '<i class="fa-solid fa-beer-mug-empty"></i>',
    url: 'https://amzn.to/3pstQTb'
  },
  {
    name: 'Música',
    images: [
      'https://m.media-amazon.com/images/I/71ni6ZfjYiL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31RGx7U5LaL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/618Fwic837L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31j1T6HxznL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81pVsncdvsL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41Rsk+cDX0L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71YVv+0k2LL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/91SLiiGA4PL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81J-z8f5tQL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81YWuO7mdQL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81Q7HZYO1rL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31VjSXtX96L._AC_UF515,515_FMjpg_._AA515_.jpg'

    ],
    iconTemplate: '<i class="fa-solid fa-record-vinyl"></i>',
    url: 'https://amzn.to/41sfAY6',
  },
  {
    name: 'Películas y Series',
    images: [
      'https://m.media-amazon.com/images/I/71Wo1AVsLRL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81ZKC0YIEgL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71NY-DzHnnL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/91tu8OqIHAL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81cPXyEncmL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51bqfvxULbL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51Q3E7WruuL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41QCMjzZlxL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51OWZQPpt8L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/81A6L9KMN+L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71ThCAq19+S._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/71VrUhaK0xL._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: '<i class="fa-solid fa-film"></i>',
    url: 'https://amzn.to/3BjimEk',
  },
  {
    name: 'Hecho a Mano',
    images: [
      'https://m.media-amazon.com/images/I/A1KrlOc5zYL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31u+TD6sCNL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31psRJR5+8L._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/21lsfsIMbGL._AC_UF515,515_FMjpg_._AA515_.jpg', 
      'https://m.media-amazon.com/images/I/41y5TfetTCL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41ZObW5gkML._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: '<i class="fa-solid fa-mountain"></i>',
    url: 'https://amzn.to/41vPd3c',
  },
  {
    name: furnituresDepartment.displayName,
    images: [
      'https://m.media-amazon.com/images/I/41WsuuxIlLL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/41B24LJwvPL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/31tpl0I+owL._AC_UF515,515_FMjpg_._AA515_.jpg',
      'https://m.media-amazon.com/images/I/51+g2TiCM9L._AC_UF515,515_FMjpg_._AA515_.jpg'
    ],
    iconTemplate: furnituresDepartment.iconTemplate,
    url: 'https://amzn.to/3NhphEL',
    hint: '¡Transforma tu hogar en un verdadero paraíso con nuestras irresistibles Ofertas del Día en Muebles!: desde el baño hasta la cocina, pasando por la recámara, sala y hasta la oficina.'
  }
];

function getCurrentDate() {
  return format(new Date(), "d 'de' MMMM 'de' y", { locale: es });
}
// components
import { OffersByDepartmentComponent } from '@components/offers-by-department/offers-by-department.component';

// interfaces
import {
  babyDepartment, bagsAndSuitcasesDepartment,
  beautyDepartment, booksDepartment, camerasAndPhotographyDepartment, 
  cellphonesAndAccessoriesDepartment, computersAndAccessoriesDepartment,
  electronicAccessoriesDepartment, fashionDepartment, 
  furnituresDepartment, gamesAndToysDepartment, 
  homeAndKitchenDepartment, homeImprovementsDepartment, 
  officeDepartment, outdoorSportsDepartment, 
  personalCareDepartment, petsDepartment, 
  tvAndVideoDepartment, videoGamesDepartment,
} from "@models/my-offers-by-departament";

export const departmentRoutes = [
  {
    path: computersAndAccessoriesDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: electronicAccessoriesDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: gamesAndToysDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: beautyDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: fashionDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: homeAndKitchenDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: outdoorSportsDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: tvAndVideoDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: booksDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: cellphonesAndAccessoriesDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: personalCareDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: homeImprovementsDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: videoGamesDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: camerasAndPhotographyDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: bagsAndSuitcasesDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: furnituresDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: babyDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: petsDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  {
    path: officeDepartment.route,
    component: OffersByDepartmentComponent,
    data: { animation: 'DepartmentTransition1' } 
  },
  
]
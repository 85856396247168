import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

// forms
import { FormGroup, FormControl, Validators } from '@angular/forms';

// environment
import { environment } from '@environment';

// rxjs
import { Subscription } from 'rxjs';

// services
import { AddToFavoritesService } from '@services/add-to-favorites.service';

// pipes
import { WordToSlugPipe } from '@pipes/word-to-slug.pipe';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {

  searchFormNavbar: FormGroup;
  searchTermFieldNavbarControl: any;
  themeStatusLabel:string;
  themeStatus:boolean;
  themeStatusIcon:any;
  themeDarkLabel: string = 'Modo obscuro';
  themeClearLabel: string = 'Modo claro';
  themeChanging: boolean = false;
  blog = environment.blog;
  isLoading: boolean;
  subscription: Subscription;
  favStatus:boolean = false;
  favoritesItemsCount: number;
  window: Window;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private _document,
    private addToFavoritesService: AddToFavoritesService) {

      // init form 
      this.searchFormNavbar = new FormGroup({
        'searchTermNavbarField': new FormControl(null, [Validators.required])
      });

      this.window = this._document.defaultView;
      this.searchTermFieldNavbarControl = this.searchFormNavbar.controls.searchTermNavbarField;
  }

  ngOnInit() {
    this.setListeners();    
    this.themeStatus = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.validateCurrentRoute();
    }, 13);
  }

  /**
   * @author C Alonso C Ortega <calonso011@yahoo.com.mx>
   * get and set router params
   */
  validateCurrentRoute() {
    if (this.window.location.pathname === '/search') {
      const button = document.querySelector('.offcanvas-toggle') as HTMLElement;
      button.click();
    }
  }

  /**
  * Get search term from submit field
  * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  * @deprecated
  */
  search(){
    if (this.searchFormNavbar.valid && !this.isLoading){
      let wordToSlug = new WordToSlugPipe();
      this.router.navigate([`buscar/`, wordToSlug.transform(this.searchTermFieldNavbarControl.value)]);
    }
  }

  /**
  * Used to change Boostrap 4 theme
  * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  * @deprecated
  */
  switchTheme(){
    const $theme = this._document.getElementById('theme');
    const url = 'assets/bootstrap';
    this.themeChanging = true;

    if(!this.themeStatus){
      // this.themeStatusIcon = this.themeClearIcon;
      this.themeStatusLabel = this.themeClearLabel;
      this.themeStatus = true;
      // $theme.setAttribute('href', `${url}/${this.themeClearCSS}`);
    }else{
      // this.themeStatusIcon = this.themeDarkIcon;
      this.themeStatusLabel = this.themeDarkLabel;
      this.themeStatus = false;
      // $theme.setAttribute('href', `${url}/${this.themeDarkCSS}`);
    }

    setTimeout(()=>{
      this.themeChanging = false;
    }, 1500);

    this.closeNavbar();
  }

  /**
   * @deprecated
   */
  closeNavbar() {
    // $('#navbarColor01').collapse('hide');
  }

  setListeners() {
    this.subscription =
    this.addToFavoritesService
    .getFavoritesItemsCount()
    .subscribe(
      data => {
        this.favoritesItemsCount = data
      }
    )
  }
}

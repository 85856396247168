<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="banner skeleton skeleton-animation-shimmer">
        <img class="player bone bone-type-image banner-top">
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex flex-row align-items-center justify-content-end">
        <div class="button skeleton skeleton-animation-shimmer me-1 my-2">
          <img class="player bone bone-type-image dropdown-content">
        </div>
      </div>
    </div>
  </div>
  <div class="a row row-cols-2 row-cols-md-4 row-cols-lg-5 g-2 | animated fadeIn ps-1 pe-1">
    <div class="col" *ngFor="let template of templates">
      <div class="card">
        <div class="skeleton skeleton-animation-shimmer">
          <div class="bone bone-type-image img-placeholder"></div>
        </div>
        <div class="card-body">
          <div class="skeleton skeleton-animation-shimmer">
            <div class="bone bone-type-heading"></div>
            <div class="d-flex align-items-center justify-content-between">
              <div class="bone bone-type-heading w-40"></div>
              <div class="bone bone-type-heading w-40"></div>
            </div>
            <div class="bone bone-type-multiline bone-style-mixed"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { OrderByCriteriaOffcanvasComponent } from '../order-by-criteria-offcanvas.component';

@NgModule({
  declarations: [
    OrderByCriteriaOffcanvasComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OrderByCriteriaOffcanvasComponent
  ]
})
export class OrderByCriteriaOffcanvasModule { }

<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasTypeahead" aria-labelledby="offcanvasTypeaheadLabel">
    <div class="offcanvas-body custom-scroll">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="offcanvas-title lead" id="offcanvasTypeaheadLabel">Ingrese un termino</p>
            <button type="button" class="btn btn-outline-secondary btn-lg" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
        <div class="mb-3">
            <input #myTypeaheadInput id="exampleFormControlInput1" type="search" 
                placeholder="Buscar entre nuestras ofertas" dir="ltr" spellcheck=false autocorrect="off" autocomplete="off"
                autocapitalize="off" maxlength="2048" class="form-control">
        </div>
        <p class="lead text-center">Ofertas en los departamentos</p>
        <div class="list-group">
            <a *ngFor="let item of myOffersByDepartament" [href]="item.url" 
                class="list-group-item list-group-item-action" target="_blank" rel="noopener noreferrer">
                <div class="a d-flex justify-content-between align-items-center">
                    <div class="a">
                        <span [innerHTML]="item.iconTemplate" class="pe-1"></span>
                        {{ item.name }}
                    </div>
                    <i class="fa-solid fa-arrow-up-right-from-square open-link"></i>
                </div>
            </a>
        </div>
    </div>
</div>
<div *ngIf="isVisible" class="toast-container p-3 bottom-0 start-50 translate-middle-x position-fixed">
    <div class="toast fade show text-dark" style="--bs-text-opacity: .91;" role="alert" aria-live="assertive" aria-atomic="true" [style.minWidth.rem]="19">
        <div class="d-flex w-100">
            <div class="toast-body">
                <p class="lead m-0 text-center">{{ message }}</p>
                <p *ngIf="title" class="one-line-ellipsis m-0">{{ title }}</p>
            </div>
            <button type="button" class="btn me-1 m-auto text-dark" style="--bs-text-opacity: .91;" aria-label="Close" (click)="hideToast()">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>
</div>
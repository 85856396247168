// departments
import {
  bagsAndSuitcasesDepartment,
  beautyDepartment, booksDepartment, camerasAndPhotographyDepartment,
  cellphonesAndAccessoriesDepartment, computersAndAccessoriesDepartment,
  electronicAccessoriesDepartment, fashionDepartment, furnituresDepartment, 
  gamesAndToysDepartment, homeAndKitchenDepartment, homeImprovementsDepartment, 
  outdoorSportsDepartment, personalCareDepartment, tvAndVideoDepartment, 
  videoGamesDepartment, babyDepartment, petsDepartment, officeDepartment
} from "@models/my-offers-by-departament";

// marketing campaings
import { hotSaleMarketingCampaing } from "@models/my-offers-by-marketing-campaing";

export const environment = {
  production: true,
  apiPROTOCOL: 'https://',
  apiHOST: 'us-central1-amazmation.cloudfunctions.net/api/v2',
  hostingDomain: 'https://ofertas-del-dia.com.mx/',
  get apiURL() {
    return `${this.apiPROTOCOL}${this.apiHOST}`;
  },
  enableDevStage: false,
  itemsPerPage: 15,
  minsToUpdate: 1080, // 18h
  loadingImage: 'assets/image/image-placeholder.jpg',
  noImage: 'assets/image/no-image.gif',
  blog: 'https://www.ofertas-del-dia.com.mx/promociones/',
  starredSeller: 'Vendido y enviado por Amazon México.',
  referLink: 'https://amzn.to/3NhphEL',
  maxFavoritesSaved: 99,
  toastDuration: 6666 - 3333, //in millis
  defaultReservedPercent: 60,
  availableDepartments: [
    electronicAccessoriesDepartment,
    bagsAndSuitcasesDepartment,
    personalCareDepartment,
    fashionDepartment,
    homeAndKitchenDepartment,
    furnituresDepartment,
    beautyDepartment,
    computersAndAccessoriesDepartment,
    gamesAndToysDepartment,
    outdoorSportsDepartment,
    booksDepartment,
    homeImprovementsDepartment,
    tvAndVideoDepartment,
    cellphonesAndAccessoriesDepartment,
    videoGamesDepartment,
    camerasAndPhotographyDepartment,
    babyDepartment, 
    petsDepartment, 
    officeDepartment
  ],
  availableMarketingCampaings: [
    hotSaleMarketingCampaing
  ]
};

import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';

// bootstrap
declare var bootstrap;

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input() ref: any;
  @HostListener('mouseenter') showTooltip() {
    const options = {
      boundary: 'window',
      trigger: 'hover focus'
    };
    let tooltipTitle = this.el.nativeElement.getAttribute('tooltip-title');
    this.renderer.setAttribute(this.el.nativeElement, 'data-placement', 'top');
    this.renderer.setAttribute(this.el.nativeElement, 'title', '');
    this.renderer.setAttribute(this.el.nativeElement, 'data-original-title', tooltipTitle);
    // $(this.el.nativeElement).tooltip(options);
    // $(this.el.nativeElement).tooltip('show');
    // Tooltip(this.el.nativeElement).show();
  }

  constructor( private el: ElementRef,
               private renderer: Renderer2 ) {
               }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagesArray'
})
export class ImagesArrayPipe implements PipeTransform {

  transform(images: string[]): string {
    const currentRandomIndex = this.randomIntFromInterval(1, images.length);
    return images[currentRandomIndex - 1];
  }

randomIntFromInterval(min:number, max:number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { DeparmentScrollingComponentComponent } from '../deparment-scrolling-component.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DeparmentScrollingComponentComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    DeparmentScrollingComponentComponent
  ]
})
export class DeparmentScrollingComponentModule { }

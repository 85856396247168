import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// models
import { FavoritesAPI, OffersAPI } from '@models/offers';

// environment
import { environment } from '@environment';


// services
import { AddToFavoritesService } from '@services/add-to-favorites.service';
import { CardService } from '@services/card.service';

@Component({
  selector: '[app-offer-card]',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.css']
})
export class OfferCardComponent implements OnInit {

  @Input('offer-param') offer: OffersAPI;
  @Input('index-param') index: number;
  @Output('countdown-param') countDownEnds: EventEmitter<number> = new EventEmitter<number>();
  loadingImage: string = environment.loadingImage;
  noImage: string = environment.noImage;
  starredSeller: string = environment.starredSeller;
  webNavigator: any;
  offerCopiedToClipboardMessage: string = 'Se copió la URL al portapapeles';
  lazyLoadOffset: number = 20;
  starRatingCssColor:string = '#e4e416';
  defaultReservedPercent: number = environment.defaultReservedPercent;

  constructor(
    private addToFavoritesService: AddToFavoritesService,
    private cardService: CardService) {
    this.webNavigator = window.navigator;
  }

  ngOnInit() {
  }

  share(text:string, url:string) {
    this.cardService.share(text, url);
  }

  goTo(url: string) {
    this.cardService.goTo(url);
  }

  addToFavorites(data: FavoritesAPI){
    this.addToFavoritesService.saveToFavorites(data);
  }
}
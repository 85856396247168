import { Component } from '@angular/core';

@Component({
  selector: 'app-complete-placeholder',
  templateUrl: './complete-placeholder.component.html',
  styleUrls: ['./complete-placeholder.component.css']
})
export class CompletePlaceholderComponent {

  //number of templates to repeat
  templates: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  
  constructor() { }
}

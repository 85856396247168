import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomTimeService {

  constructor() { }

  // docs https://stackoverflow.com/a/7228322/7724301
  private randomIntFromInterval(min: number, max:number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  getRandomTimeForPlaceholder(){
    const minTime = 250; // time in ms
    const maxTime = 360; // time in ms
    return this.randomIntFromInterval(minTime, maxTime);
  }

  getRandomLongTimeForPlaceholder(){
    const minTime = 333; // time in ms
    const maxTime = 666; // time in ms
    return this.randomIntFromInterval(minTime, maxTime);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { AmazonMexicoComponent } from '../amazon-mexico/amazon-mexico.component';
import { BlackFriday2022Component } from '../black-friday2022/black-friday2022.component';
import { BuenFin2022Component } from '../buen-fin2022/buen-fin2022.component';
import { HotSale2022Component } from '../hot-sale2022/hot-sale2022.component';
import { HotSale2023Component } from '../hot-sale2023/hot-sale2023.component';

@NgModule({
  declarations: [
    AmazonMexicoComponent,
    BlackFriday2022Component,
    BuenFin2022Component,
    HotSale2022Component,
    HotSale2023Component
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AmazonMexicoComponent,
    BlackFriday2022Component,
    BuenFin2022Component,
    HotSale2022Component,
    HotSale2023Component
  ]
})
export class BannerTopModule { }

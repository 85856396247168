<div class="col-md-12">
  <a [href]="referLink" target="_blank" rel="noopener noreferrer" alt="Ir a Amazon México">
    <div class="card">
      <div class="card-body amazon">
        <h1 class="text-center display-6">Descubre las mejores Ofertas</h1>
        <div class="d-flex justify-content-center">
          <img src="assets/logo/amazon_logo.svg" class="img-fluid amason-logo" alt="Amazon logo">
        </div>
      </div>
    </div>
  </a>
</div>
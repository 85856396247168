import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

// environment
import { environment } from '@environment';

// interface
import { elBuenFin, hotSaleMarketingCampaing } from '@models/my-offers-by-marketing-campaing';
import { OffersAPI } from '@models/offers';
import { OrderByDefinition, OrderByName, OrderPipeConfig } from '@models/orderBy';
import { PlaceholderType } from '@models/placeholder';

// services
import { ContentPlaceholderService } from '@services/content-placeholder.service';
import { OffersRestApiService } from '@services/offers-rest-api.service';
import { OrderByService } from '@services/order-by.service';
import { RandomTimeService } from '@services/random-time.service';

// 3-party
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-offers-by-marketing-campaing',
  templateUrl: './offers-by-marketing-campaing.component.html',
  styleUrls: ['./offers-by-marketing-campaing.component.css']
})
export class OffersByMarketingCampaingComponent implements OnInit {

  orderByPipeConfig: OrderPipeConfig;
  currentMasonryItems: OffersAPI[] = [];
  referLink: string = environment.referLink;

  // campaing variables
  myHotSale = hotSaleMarketingCampaing;
  myElBuenFin = elBuenFin;

  // status variables
  showTopContent: boolean = false;
  showBodyContent: boolean = false;
  noResults: boolean;
  applyHiddenStyleOfferListContent: boolean = false;
  tempMasonryItems: OffersAPI[];

  // seo variables
  currentPageTitle: string;
  currentPageDescription: string;

  constructor(
    private router: Router,
    private orderPipe: OrderPipe,
    private orderByService: OrderByService,
    private offersRestApiService: OffersRestApiService,
    private contentPlaceHolderService: ContentPlaceholderService,
    private randomTimeService: RandomTimeService,
    private meta: Meta,
    private title: Title
  ) { }

  ngOnInit(): void {
    this.setMetaTagDescription();
    this.setTitle();
    this.initOrderBy();
    this.getCurrentData();
  }

  setMetaTagDescription() {
    const currentRouteUrl = this.router.url;
    const currentElDescription = environment.availableMarketingCampaings.find(el => currentRouteUrl.includes(el.route));

    this.meta.updateTag(
      {
        name: 'description',
        content: currentElDescription.description
      }
    );
    this.currentPageDescription = currentElDescription.description;
  }

  setTitle() {
    const currentRouteUrl = this.router.url;
    const currentElTitle = environment.availableMarketingCampaings.find(el => currentRouteUrl.includes(el.route));

    this.title.setTitle(currentElTitle.title);
    this.currentPageTitle = currentElTitle.title;
  }

  getCurrentData() {
    this.offersRestApiService
    .getOffersList()
    .subscribe({
      next: (data) => {
        if (data.offersList.length) {
          this.currentMasonryItems = this.applyOrderBy(data.offersList);
          this.showTopContent = true;
          this.showBodyContent = true;
        } else {
          this.noResults = true;
        }
      },
      error: () => {}
    })
  }

  initOrderBy() {
    let curentOrderByName: OrderByName = OrderByName.lastest;
    this.orderByPipeConfig = this.orderByService.getOrderByOption(curentOrderByName).config;
    this.orderByService.setCurrentOrderBy(this.orderByService.getOrderByOption(curentOrderByName));
  }

  applyOrderBy(currentItems: OffersAPI[]): OffersAPI[] {
    return this.orderPipe.transform(currentItems, this.orderByPipeConfig.expression, this.orderByPipeConfig.reverse, this.orderByPipeConfig.caseInsensitive, this.orderByPipeConfig.comparator);
  }

  /**
   * Set config for use into ngx order pipe.
   * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
   */
  setCurrentOrderByCriteria(el: OrderByDefinition) {

    this.applyHiddenStyleOfferListContent = true;
    this.contentPlaceHolderService.setIsLoading(PlaceholderType.partial);

    setTimeout(() => {
      this.showBodyContent = false;
      // apply new order by config
      this.orderByPipeConfig = el.config;
      this.renderOrderBy();

      setTimeout(() => {
        this.applyHiddenStyleOfferListContent = false;
        this.showBodyContent = true;
        this.contentPlaceHolderService.setLoaded(PlaceholderType.partial);
      }, this.randomTimeService.getRandomLongTimeForPlaceholder());
    }, 303);
  }

  renderOrderBy() {
    this.tempMasonryItems = this.applyOrderBy(this.currentMasonryItems);
    this.currentMasonryItems = [];

    setTimeout(() => {
      this.currentMasonryItems = this.tempMasonryItems;
    }, 303);
  }

  
}

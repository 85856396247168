import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { PlaceholderType, PlaceholderStatus } from '@models/placeholder';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentPlaceholderService {

  readonly currentPlaceholderTypes: PlaceholderType;
  private stateIsLoading: boolean;
  private statePlaceholderType: PlaceholderType;
  private _isLoadingV2: BehaviorSubject<PlaceholderStatus>;
  private renderer: Renderer2;
  
  constructor( rendererFactory: RendererFactory2 ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.stateIsLoading = false;
    this.statePlaceholderType = PlaceholderType.complete;

    this._isLoadingV2 = new BehaviorSubject<PlaceholderStatus>({
      loading: this.stateIsLoading,
      placeholderType: this.statePlaceholderType
    });

  }

  getContentPlaceHolderStatus(): Observable<PlaceholderStatus> {
    return this._isLoadingV2;
  }

  setIsLoading(currentPlaceholderType: PlaceholderType): void {
    this.statePlaceholderType = currentPlaceholderType;
    this.stateIsLoading = true;

    this.lockBodyScrolling(true);

    this._isLoadingV2.next({
      loading: this.stateIsLoading,
      placeholderType: this.statePlaceholderType
    });
  }

  setLoaded(currentPlaceholderType: PlaceholderType): void{
    this.statePlaceholderType = currentPlaceholderType;
    this.stateIsLoading = false;

    this.lockBodyScrolling(false);
    
    this._isLoadingV2.next({
      loading: this.stateIsLoading,
      placeholderType: this.statePlaceholderType
    });
  }

  lockBodyScrolling(lock: boolean) {
    if (lock) {
      //add css      
      this.renderer.setStyle(document.body, 'overflowY', 'hidden');
      this.renderer.setStyle(document.body, 'overflowX', 'hidden');

    } else {
      //remove css
      this.renderer.setStyle(document.body, 'overflowY', 'auto');
      this.renderer.setStyle(document.body, 'overflowX', 'auto');
      this.renderer.setStyle(document.body, 'paddingRight', '0px');
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { OfferCardContainerComponent } from '../offer-card-container.component';
import { OfferCardComponent } from '@components/shared/offer-card/offer-card.component';
import { DepartamentCardComponent } from '@components/departament-card/departament-card.component';

// 3-party
import { OrderModule } from 'ngx-order-pipe';

// pipes
import { PipesModule } from '@pipes/module/pipes.module';

// directives
import { DirectivesModule } from '@directives/directives.module';

// modules
import { ToastModule } from '@components/toast/module/toast.module';


@NgModule({
  declarations: [
    OfferCardContainerComponent,
    OfferCardComponent,
    DepartamentCardComponent
  ],
  imports: [
    CommonModule,
    OrderModule,
    PipesModule,
    DirectivesModule,
    ToastModule
  ],
  exports: [
    OfferCardContainerComponent
  ]
})
export class OfferCardContainerModule { }

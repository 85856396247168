import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import packageJson from './../../package.json';


// animatios
import { AppAnimations, DeleteAnimations } from '@shared/animations';

// services
import { ToastService } from './toast.service';

// environment
import { environment } from '@environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    AppAnimations,
    DeleteAnimations
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit{
  title = 'ng-app';
  appUpdatedMessage: string = 'Actualizamos la aplicación a una versión más reciente';
  constructor(
    private swUpdate: SwUpdate,
    private toastService: ToastService ){

  }
  ngOnInit(): void {
    
    if (environment.production) {
      this.diMiSigilio();
      console.log('Status page: https://alonsok28.github.io/amzmation-upptime/');
      // example https://stackoverflow.com/a/13017382/7724301
      console.log(`%c site v.${packageJson.version}`, 'background: #222; color: #bada55');
    }
    this.checkServiceWorkerUpdate();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  checkServiceWorkerUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .subscribe((evt) => {
          switch (evt.type) {
            case 'VERSION_DETECTED':
              console.log(`Downloading new app version: ${evt.version.hash}`);
              break;

            case 'VERSION_READY':
              this.applyUpdate();
              break;

            case 'VERSION_INSTALLATION_FAILED':
              console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
              break;
          }
      });
    }
  }

  applyUpdate() {
    const promise = new Promise((resolve) => {
      this.toastService.showToastNotify(this.appUpdatedMessage);
      setTimeout(() => {
        resolve(true);
      }, 8500);
    });

    promise.then(() => {
      window.location.reload();
    });
  }

  diMiSigilio() {
    console.warn('We want grow up, if you want help us please contact me via mail to "calonso011@yahoo.com.mx" or send a message via "https://github.com/AlonsoK28"');
    console.warn('We can help you to manage your eCommerce process and build any system that you need');

    console.log('Yo soy Icon San Angel');
    console.log('Yo soy Icon San Angel');
    console.log('Yo soy Icon San Angel');

    console.log('Y voy a obtener 133 millones de pesos por la venta del eCommerce de Xadani');
    console.log('Y voy a obtener 133 millones de pesos por la venta del eCommerce de Xadani');
    console.log('Y voy a obtener 133 millones de pesos por la venta del eCommerce de Xadani');

    console.log('Los recursos de Icon San Angel');
    console.log('Los recursos de Icon San Angel');
    console.log('Los recursos de Icon San Angel');

    console.log('YsISA');
    console.log('YsISA');
    console.log('YsISA');
  }
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentege'
})
export class PercentegePipe implements PipeTransform {

  transform(percentege: string, type: string = 'default'): unknown {
    if(percentege){
      switch (type) {
        case 'default':
          return `${percentege}%`;
        case 'title':
          return `Apartados ${percentege}%`;
      }
    }
  }

}
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

// models
import { Departament } from '@models/departament';
import { offersByDepartament } from '@models/my-offers-by-departament';

// services
import { CardService } from '@services/card.service';

@Component({
  selector: '[app-departament-card]',
  templateUrl: './departament-card.component.html',
  styleUrls: ['./departament-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepartamentCardComponent implements OnInit {

  currentDepartament: Departament;
  @Input() index:number;

  constructor(
    private cardService: CardService
  ) { }

  ngOnInit(): void {
    this.getRandomDepartmentItem();
  }

  getRandomDepartmentItem() {
    this.currentDepartament = offersByDepartament.sort(() => Math.random() - 0.85)[0];
  }

  goTo(url: string) {
    this.cardService.goTo(url);
  }

}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

// interface
import { OfferAPIResponse, OffersAPI } from '@models/offers';
import { OrderByDefinition, OrderByName, OrderPipeConfig } from '@models/orderBy';
import { PlaceholderType } from '@models/placeholder';
import { 
  babyDepartment, bagsAndSuitcasesDepartment, beautyDepartment, 
  booksDepartment, camerasAndPhotographyDepartment, 
  cellphonesAndAccessoriesDepartment, computersAndAccessoriesDepartment, 
  electronicAccessoriesDepartment, fashionDepartment, furnituresDepartment, 
  gamesAndToysDepartment, homeAndKitchenDepartment, homeImprovementsDepartment, 
  officeDepartment, outdoorSportsDepartment, personalCareDepartment, petsDepartment, 
  tvAndVideoDepartment, videoGamesDepartment 
} from '@models/my-offers-by-departament';
import { DepartmentInternalDetails } from '@models/departament';

// services
import { OrderByService } from '@services/order-by.service';
import { CanonicalTagService } from 'app/canonical-tag.service';
import { RandomTimeService } from '@services/random-time.service';
import { ContentPlaceholderService } from '@services/content-placeholder.service';
import { OffersByDepartmentService } from '@services/offers-by-department.service';

// 3-party
import { OrderPipe } from 'ngx-order-pipe';

// rxjs
import { Observable, of, switchMap } from 'rxjs';

// environment
import { environment } from '@environment';

@Component({
  selector: 'app-offers-by-department',
  templateUrl: './offers-by-department.component.html',
  styleUrls: ['./offers-by-department.component.css']
})
export class OffersByDepartmentComponent implements OnInit, AfterViewInit {

  orderByPipeConfig: OrderPipeConfig;
  currentMasonryItems: OffersAPI[] = [];

  // status variables
  showTopContent: boolean = false;
  showBodyContent: boolean = false;
  noResults: boolean;
  currentNoResultsDepartment: DepartmentInternalDetails;
  applyHiddenStyleOfferListContent: boolean = false;
  tempMasonryItems: OffersAPI[];

  // seo variables
  currentPageTitle: string;
  currentPageDescription: string;

  // scrolling
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  scrollStarted: boolean = true;
  scrollEnded: boolean = false;
  

  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router,
    private _location: Location,
    private orderPipe: OrderPipe,
    private orderByService: OrderByService,
    private randomTimeService: RandomTimeService,
    private canonicalTagService: CanonicalTagService,
    private offersByDepartmentService: OffersByDepartmentService,
    private contentPlaceHolderService: ContentPlaceholderService,
  ) { }

  ngOnInit(): void {
    this.setTitle();
    this.setCanonicalTag();
    this.setMetaTagDescription();
    this.setMetaOpenGraphProperties();
    this.getCurrentDepartmentData();
    this.initOrderBy();
  }

  ngAfterViewInit(): void {
    this.scrollableDiv?.nativeElement?.addEventListener('scroll', this.onScroll.bind(this));
  }

  // code from chatgpt https://chatgpt.com/share/b3fa1acd-cfea-45ad-ac32-21456573e044
  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    const scrollLeft = target.scrollLeft;
    const scrollWidth = target.scrollWidth;
    const clientWidth = target.clientWidth;

    if (scrollLeft === 0) {
      console.log('Reached the start of the scroll');
      this.scrollStarted = true;
    } else if (scrollLeft + clientWidth >= scrollWidth) {
      console.log('Reached the end of the scroll');
      this.scrollEnded = true;
    } else {
      console.log('Scrolling...');
      this.scrollStarted = false;
      this.scrollEnded = false;
    }
  }

  getCurrentDepartmentData():void {
    const routeUrl = this.router.url;

    of(routeUrl)
      .pipe(
        switchMap(val => this.getCurrentDepartmentObservable(val))
      ).subscribe({
        next: data => {
          if (data.offersList) {
            this.currentMasonryItems = data.offersList;
            this.showTopContent = true;
            this.showBodyContent = true;
          } else {
            this.displayNoResultsMessage();
          }
        },
        error: () => {
          this.displayNoResultsMessage();
        }
      })
  }

  setCanonicalTag() {
    const currentRouteUrl = this.router.url;
    const currentEl = environment.availableDepartments.find(el => currentRouteUrl.includes(el.route));
    this.canonicalTagService.setCanonicalURL(currentEl.route);
  }

  setMetaTagDescription() {
    const currentRouteUrl = this.router.url;
    const currentElDescription = environment.availableDepartments.find(el => currentRouteUrl.includes(el.route));

    this.meta.updateTag(
      {
        name: 'description',
        content: currentElDescription.description
      }
    );

    this.currentPageDescription = currentElDescription.description;
  }
  
  setMetaOpenGraphProperties() {
    const currentRouteUrl = this.router.url;
    const currentEl = environment.availableDepartments.find(el => currentRouteUrl.includes(el.route));
    const currentUrl = `${environment.hostingDomain}${currentEl.route}`;

    // docs https://ogp.me/#optional
    this.meta.updateTag(
      {
        property: 'og:locale',
        content: 'es-MX',
      }
    );

    // docs https://ogp.me/#types
    this.meta.updateTag(
      {
        property: 'og:type',
        content: 'website',
      }
    );
    
    // docs https://ogp.me/#metadata
    this.meta.updateTag(
      {
        property: 'og:title',
        content: currentEl.title,
      }
    );
    
    // docs https://ogp.me/#structured
    this.meta.updateTag(
      {
        property: 'og:image',
        content: currentEl.imagePreview,
      }
    );
    
    // docs https://ogp.me/#metadata
    this.meta.updateTag(
      {
        property: 'og:url',
        content: currentUrl,
      }
    );
  }
 
  setTitle() {
    const currentRouteUrl = this.router.url;
    const currentElTitle = environment.availableDepartments.find(el => currentRouteUrl.includes(el.route));
    
    this.title.setTitle(currentElTitle.title);
    this.currentPageTitle = currentElTitle.title;
  }

  initOrderBy() {
    let curentOrderByName: OrderByName = OrderByName.lastest;
    this.orderByPipeConfig = this.orderByService.getOrderByOption(curentOrderByName).config;
    this.orderByService.setCurrentOrderBy(this.orderByService.getOrderByOption(curentOrderByName));
  }

  applyOrderBy(currentItems: OffersAPI[]): OffersAPI[] {
    return this.orderPipe.transform(currentItems, this.orderByPipeConfig.expression, this.orderByPipeConfig.reverse, this.orderByPipeConfig.caseInsensitive, this.orderByPipeConfig.comparator);
  }

  /**
   * Set config for use into ngx order pipe.
   * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
   */
  setCurrentOrderByCriteria(el: OrderByDefinition) {

    this.applyHiddenStyleOfferListContent = true;
    this.contentPlaceHolderService.setIsLoading(PlaceholderType.partial);

    setTimeout(() => {
      this.showBodyContent = false;
      // apply new order by config
      this.orderByPipeConfig = el.config;
      this.renderOrderBy();

      setTimeout(() => {
        this.applyHiddenStyleOfferListContent = false;
        this.showBodyContent = true;
        this.contentPlaceHolderService.setLoaded(PlaceholderType.partial);
      }, this.randomTimeService.getRandomLongTimeForPlaceholder());
    }, 303);
  }

  renderOrderBy() {
    this.tempMasonryItems = this.applyOrderBy(this.currentMasonryItems);
    this.currentMasonryItems = [];

    setTimeout(() => {
      this.currentMasonryItems = this.tempMasonryItems;
    }, 303);
  }

  /**
   * returns observable for current department
   * docs https://stackoverflow.com/a/64475459/7724301
   */
  getCurrentDepartmentObservable(currentDepartment: string): Observable<OfferAPIResponse> {    
    switch (true) {
      case currentDepartment.includes(computersAndAccessoriesDepartment.route):
        return this.offersByDepartmentService.getByDepartmentComputersAndAccessories();

      case currentDepartment.includes(electronicAccessoriesDepartment.route):
        return this.offersByDepartmentService.getByDepartmentElectronicAccessories();

      case currentDepartment.includes(gamesAndToysDepartment.route):
        return this.offersByDepartmentService.getByDepartmentGamesAndToys();

      case currentDepartment.includes(beautyDepartment.route):
        return this.offersByDepartmentService.getByDepartmentBeauty();

      case currentDepartment.includes(fashionDepartment.route):
        return this.offersByDepartmentService.getByDepartmentFashion();

      case currentDepartment.includes(homeAndKitchenDepartment.route):
        return this.offersByDepartmentService.getByDepartmentHomeAndKitchen();

      case currentDepartment.includes(outdoorSportsDepartment.route):
        return this.offersByDepartmentService.getByDepartmentOutdoorSports();

      case currentDepartment.includes(tvAndVideoDepartment.route):
        return this.offersByDepartmentService.getByDepartmentTvAndVideo();

      case currentDepartment.includes(booksDepartment.route):
        return this.offersByDepartmentService.getByDepartmentBooks();

      case currentDepartment.includes(cellphonesAndAccessoriesDepartment.route):
        return this.offersByDepartmentService.getByDepartmentCellphonesAndAccessories();

      case currentDepartment.includes(personalCareDepartment.route):
        return this.offersByDepartmentService.getByDepartmentPersonalCare();

      case currentDepartment.includes(homeImprovementsDepartment.route):
        return this.offersByDepartmentService.getByDepartmentHomeImprovements();

      case currentDepartment.includes(videoGamesDepartment.route):
        return this.offersByDepartmentService.getByDepartmentVideogames();

      case currentDepartment.includes(camerasAndPhotographyDepartment.route):
        return this.offersByDepartmentService.getByDepartmentCamerasAndPhotography();

      case currentDepartment.includes(bagsAndSuitcasesDepartment.route):
        return this.offersByDepartmentService.getByDepartmentBagsAndSuitcases();

      case currentDepartment.includes(furnituresDepartment.route):
        return this.offersByDepartmentService.getByDepartmentFurnitures();

      case currentDepartment.includes(babyDepartment.route):
        return this.offersByDepartmentService.getByDepartmentBaby();

      case currentDepartment.includes(petsDepartment.route):
        return this.offersByDepartmentService.getByDepartmentPets();

      case currentDepartment.includes(officeDepartment.route):
        return this.offersByDepartmentService.getByDepartmentOffice();
    }
  }

  moveScrollNext() {
    this.offersByDepartmentService.moveScrollNext();
  }

  moveScrollBack() {
    this.offersByDepartmentService.moveScrollBack();
  }

  goBack(){
    this._location.back();
  }

  displayNoResultsMessage(){
    const currentRouteUrl = this.router.url;
    this.currentNoResultsDepartment = environment.availableDepartments.find(el => currentRouteUrl.includes(el.route));
    this.noResults = true;
  }
}

<!-- Boostrap template card https://codepen.io/cristinaconacel/pen/NOYoGX -->
<div class="card h-100 border-primary">
    <div (click)="goTo(offer.url)" class="hand-cursor">
        <img class="card-img" loading="lazy" [srcset]="offer.image | imageSize" [alt]="offer.title">
    </div>
    <div class="d-flex d-flex justify-content-evenly align-items-center align-items-center w-100 | pt-2 px-1">
        <button *ngIf="offer.discount" type="button" class="btn btn-primary | p-1">
            <span class="a lead">{{ offer.discount }}</span>
        </button>
        <span *ngIf="offer.discountDescribed" class="a ms-1 lead text-muted text-truncate" [style.maxWidth.px]="130">
            {{ offer.discountDescribed }}
        </span>
    </div>
    <div class="card-body | pt-2 ps-2 pe-2 pb-2">
        <div (click)="goTo(offer.url)" class="card-link hand-cursor">
            <h5 class="card-title mb-0 title d-flex align-items-center text-center">
                <span class="elipsis">{{offer.title}}</span>
            </h5>
        </div>
        <div *ngIf="offer.reserved >= 1 && offer.reserved < defaultReservedPercent; else reserved" class="progress my-1">
            <div class="progress-bar bg-secondary-subtle" role="progressbar" [style.width]="defaultReservedPercent + '%'">
                <span class="text-dark">Canjeado {{offer.reserved}}%</span>
            </div>
        </div>
        <ng-template #reserved>
            <div *ngIf="offer.reserved >= 1" class="progress hand-cursor my-1">
                <div class="progress-bar bg-secondary-subtle" role="progressbar" [style.width]="offer.reserved | percentege">
                    <span class="text-dark">Canjeado {{offer.reserved}}%</span>
                </div>
            </div>
        </ng-template>
        <div [ngClass]="!offer.ratingStar || !offer.rating ? 'mt-1' : ''"
            class="d-flex flex-no-wrap enable-scroll custom-scroll">
            <div class="col">
                <button (click)="goTo(offer.url)" type="button" class="btn btn-outline-warning buy me-2 my-1">
                    <span class="lead">Ver</span>
                </button>
            </div>
            <div class="col">
                <button (click)="addToFavorites(offer)" type="button" class="btn btn-outline-dark fav-btn my-1"
                    appTooltip tooltip-title="Guardar en Favoritos" appBounceOnClick
                    [ngClass]="[webNavigator.share ? 'me-2' : '' ]">
                    <div class="d-flex align-items-center">
                        <span class="lead">Guardar</span>
                        <i class="fa-regular fa-heart ps-1"></i>
                    </div>
                </button>
            </div>
            <div *ngIf="webNavigator.share" class="col">
                <button (click)="share( offer.title, offer.url )" type="button"
                    class="btn btn-outline-dark my-1" appTooltip tooltip-title="Compartir" appBounceOnClick>
                    <div class="d-flex align-items-center">
                        <span class="font-regular">Compartir</span>
                        <i class="fa-solid fa-share-from-square ps-1"></i>
                    </div>
                </button>
            </div>
            <div class="col" *ngIf="webNavigator.share">
                <div class="a me-3"></div>
            </div>
        </div>
    </div>
</div>
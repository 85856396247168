import { Pipe, PipeTransform } from '@angular/core';
import formatDistance from 'date-fns/formatDistance';
import es from 'date-fns/esm/locale/es'

@Pipe({
  name: 'updatedAgo'
})
export class UpdatedAgoPipe implements PipeTransform {

  transform(dateAgo: number): string {
    const timeElapsed = new Date(dateAgo);
    const now = new Date();
    const distanceFormat = formatDistance(now, timeElapsed, { locale: es });
    if(distanceFormat.includes('minuto')){
      return `Hace ${distanceFormat}`;
    }else{
      return distanceFormat;
    }
  }
}

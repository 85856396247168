import { Injectable } from '@angular/core';
import { httpError, httpErrorCode } from '../models/httpError';
import { HttpClient } from '@angular/common/http';

// date-fns
import differenceInMinutes from 'date-fns/differenceInMinutes'

// RxJS
import { Observable, throwError, of } from 'rxjs';

// model
import { OfferAPIResponse, OffersAPI, OffersAPILocalStorage } from '../models/offers';

// envrironment
import { environment } from '../../environments/environment';

// scully
import { TransferStateService } from '@scullyio/ng-lib';

@Injectable({
  providedIn: 'root'
})
export class OffersRestApiService {

  apiURL:string = environment.apiURL;
  minsToUpdate: number = environment.minsToUpdate;
  httpE: httpError;
  offersFromLocalStorage: OffersAPILocalStorage;
  offersUpdatedMessage: string = 'Actualizamos las Ofertas';

  constructor( 
    private http: HttpClient,
    private transferState: TransferStateService
    ) { }

  /**
  * Get a list of Deals from Server.
  * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  * @return [Offer]
  */
  getOffersList(): Observable<OfferAPIResponse> {
    const url = this.validateCurrentStage('getOffers');
    return this.transferState
      .useScullyTransferState(
        'offerlist',
        this.http.get<OfferAPIResponse>(`${this.apiURL}/${url}`)
    )
  }

  /**
  * Search in list of Deals.
  * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  * @return [Offer]
  */
  searchInOffersList(query: string): Observable<OffersAPI[]> {
    const regExp = new RegExp(query, 'gi');
    return this.getOffersList()
      .pipe(
        data => {
          const result = data[0].offersList.filter(data => data.title.search(regExp) != -1);
          return result ? of(result) : of([]);
        }
      )
  }

  /**
  * Check for a list of Deals in Local storage.
  * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  * @return [Offer]
  * @deprecated
  */
  getOffersListFromLocalStorage() {
    this.offersFromLocalStorage = this.getDataFromLocalStorage();

    if (!this.offersFromLocalStorage) {
      console.info('No data in local storage was found ❌');
      return false;
    }

    const now = new Date();
    const localStorageDataDate = this.offersFromLocalStorage.data[0].date;
    const updatedAgo = differenceInMinutes(now, new Date(localStorageDataDate));
    if (updatedAgo <= this.minsToUpdate) {
      console.info('There are already saved offers in Local Storage ✔️');
      return this.offersFromLocalStorage.data;
    } else {
      console.info('Data need to be updated ❌');
      // this.alertService.showInfo(this.offersUpdatedMessage);
      return false;
    }
  }

  /**
    * Check if offerList exist using Local storage API.
    * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
    * @return [Offer]
    * @deprecated
  */
  getDataFromLocalStorage() {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem('OfferList'))
    if (dataFromLocalStorage) {
      return dataFromLocalStorage;
    }
  }

  /**
  * Save a list of Deals in Local storage.
  * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  * @return [Offer]
  * @deprecated
  */
  saveOffersListToLocalStorage(data: OffersAPI[]) {
    localStorage.setItem('OfferList', JSON.stringify({
      date: new Date(),
      data
    }));
  }

  /**
  * Observer error handler, crate an new observer with http error code to be validated in template
  * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
  */
  handleError(error) {
    console.error(error);
    switch (error.status) {
      case httpErrorCode[0].code:
        var httpError = httpErrorCode[0];
        this.httpE = {
          httpStatusCode: httpError.code,
          httpErrorMessage: httpError.message
        };
        break;
      case httpErrorCode[500].code:
        var httpError = httpErrorCode[500];
        this.httpE = {
          httpStatusCode: httpError.code,
          httpErrorMessage: httpError.message
        };
        break;
      case httpErrorCode[404].code:
        var httpError = httpErrorCode[404];
        this.httpE = {
          httpStatusCode: httpError.code,
          httpErrorMessage: httpError.message
        };
        break;
      case httpErrorCode[409].code:
        var httpError = httpErrorCode[409];
        this.httpE = {
          httpStatusCode: httpError.code,
          httpErrorMessage: httpError.message
        };
        break;
    }
    return throwError(this.httpE);
  }

  validateCurrentStage(url: string) {
    if (!environment.production && environment.enableDevStage) {
      return url + 'Dev';
    } else {
      return url;
    }
  }
}

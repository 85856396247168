import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { OffersListComponent } from '../offers-list.component';

// 3-party
import { OrderModule } from 'ngx-order-pipe';

// directives
import { DirectivesModule } from '@directives/directives.module';

// pipes
import { PipesModule } from '@pipes/module/pipes.module';

// modules
import { PlaceholderContainerModule } from '@components/shared/placeholder/placeholder-container/module/placeholder-container.module';
import { OfferCardContainerModule } from '@components/shared/offer-card-container/module/offer-card-container.module';
import { OrderByCriteriaOffcanvasModule } from '@components/order-by-criteria-offcanvas/module/order-by-criteria-offcanvas.module';
import { MyBackToTopModule } from '@components/my-back-to-top/module/my-back-to-top.module';
import { DeparmentScrollingComponentModule } from '@components/deparment-scrolling-component/module/deparment-scrolling-component.module';
import { BannerTopModule } from '@components/banner-top/module/banner-top.module';
import { JsonLdModule } from '@components/json-ld/module/json-ld.module';
import { TypeaheadOffcanvasModule } from '@components/typeahead-offcanvas/module/typeahead-offcanvas.module';

@NgModule({
  declarations: [
    OffersListComponent
  ],
  imports: [
    CommonModule,
    PlaceholderContainerModule,
    DirectivesModule,
    PipesModule,
    OfferCardContainerModule,
    OrderModule,
    OrderByCriteriaOffcanvasModule,
    MyBackToTopModule,
    DeparmentScrollingComponentModule,
    BannerTopModule,
    JsonLdModule,
    TypeaheadOffcanvasModule
  ]
})
export class OffersListModule { }

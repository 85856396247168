<app-typeahead-offcanvas [dataToSearchIn]="currentMasonryItems"></app-typeahead-offcanvas>

<!-- ld+json seo script -->
<app-json-ld *ngIf="showBodyContent" [arr]="currentMasonryItems" [title]="currentPageTitle" [description]="currentPageDescription">
</app-json-ld>
<!-- ld+json seo script -->


<div *ngIf="showTopContent">
    <div class="container my-2 my-sm-3">
        <div class="row">
            <div class="col-md-12">
                <container-element [ngSwitch]="true">
                    <app-hot-sale2022 
                        *ngSwitchCase="router.url.includes(myHotSale.route)" 
                        [referLink]="referLink">
                    </app-hot-sale2022>
                    <!-- <app-buen-fin2022 *ngSwitchCase="router.url.includes(myElBuenFin.route)"
                        [referLink]="referLink">
                    </app-buen-fin2022> -->
                </container-element>
                <!-- <app-amazon-mexico [referLink]="referLink"></app-amazon-mexico> -->
                <!-- <app-black-friday2022 [referLink]="referLink"></app-black-friday2022> -->
            </div>
        </div>
    </div>
    <div *ngIf="showBodyContent && !applyHiddenStyleOfferListContent" class="container-fluid my-2">
        <div class="row">
            <div class="d-flex justify-content-end align-items-center">
                <!-- hidden on mobile -->
                <div app-deparment-scrolling-component class="a d-flex flex-nowrap | scrolling-wrapper| custom-scroll | snap-type-x"
                    style="overflow-x: auto; padding-bottom: 3px;" [extraClass]="'d-none d-sm-block'">
                </div>
                <app-order-by-criteria-offcanvas (onChangedOrderByCriteria)="setCurrentOrderByCriteria($event)"
                    [currentPage]="currentPage">
                </app-order-by-criteria-offcanvas>
            </div>
            <div class="a">
                <!-- visible only on desktop -->
                <div app-deparment-scrolling-component
                    class="a d-flex flex-nowrap | scrolling-wrapper | custom-scroll | snap-type-x | mt-2"
                    style="overflow-x: auto; padding-bottom: 3px;" [extraClass]="'d-block d-sm-none'">
                </div>
            </div>
        </div>
    </div>
</div>

<app-placeholder-container></app-placeholder-container>

<div class="offer-list main-container">
    <app-offer-card-container *ngIf="showBodyContent" [offers-param]="currentMasonryItems"
        [displayDeparmentCards]="false"
        [ngClass]="hiddenOfferListContent ? 'visibility:hidden;' : 'visibility:visible;'">
    </app-offer-card-container>
</div>

<div *ngIf="showBodyContent" class="container">
    <div class="row">
        <div class="col-md-12 mb-5 mt-2">
            <div class="a d-flex justify-content-end align-items-center">
                <p class="lead mb-0 updated-ago-info">
                    <i class="fa-regular fa-calendar me-1"></i>
                    Actualizado {{ currentMasonryItems[0].date | updatedAgo | lowercase }}
                </p>
            </div>
        </div>
    </div>
</div>
<app-my-back-to-top></app-my-back-to-top>
<div class="card h-100 hand-cursor" (click)="goTo(currentDepartament.url)">
    <img [src]="currentDepartament.images | imagesArray | domSanitizer" class="card-img-top" [alt]="currentDepartament.name">
    <div class="d-flex align-items-center justify-content-center card-body | pt-2 ps-2 pe-2 pb-2">
        <div class="a d-flex flex-column justify-content-center">
            <p class="lead text-center" [ngClass]="currentDepartament.hint ? 'm-0' : ''">
                Ofertas en {{ currentDepartament.name }}
            </p>
            <span *ngIf="currentDepartament.hint" class="text-muted text-center elipsis-5 my-1">
                {{currentDepartament.hint}}
            </span>
            <button type="button" class="btn btn-secondary">Ver la Oferta</button>
        </div>
    </div>
</div>
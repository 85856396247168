import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class CanonicalTagService {

  constructor() { }

  // chatGPT example https://chatgpt.com/share/8e181d21-ffbf-4bd6-b277-a03458b0d4bb
  setCanonicalURL(url?: string) {
    let canURL = url === undefined ? this.getCurrentUrl() : this.getCurrentUrl() + url;
    let link: HTMLLinkElement = this.getLinkElement();

    if (link) {
      link.setAttribute('href', canURL);
    } else {
      link = this.createLinkElement(canURL);
      document.head.appendChild(link);
    }
  }

  private getLinkElement(): HTMLLinkElement {
    return document.querySelector("link[rel='canonical']");
  }

  private createLinkElement(url: string): HTMLLinkElement {
    let link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    return link;
  }

  private getCurrentUrl(): string {
    return environment.hostingDomain;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partial-placeholder',
  templateUrl: './partial-placeholder.component.html',
  styleUrls: ['./partial-placeholder.component.css']
})
export class PartialPlaceholderComponent {

  templates: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  
  constructor() { }

}

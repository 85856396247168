import { Injectable } from '@angular/core';

// components
import { ToastComponent } from '@components/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastState: ToastComponent;


  constructor() { }

  showToastNotify(message: string) {
    this.toastState.showToast(message);
  }

  showToastOffer(message: string, title: string) {
    this.toastState.showToast(message, title);
  }
}

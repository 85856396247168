import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

// models
import { FavoritesAPI } from '@models/offers';

// services
import { environment } from '@environment';
import { ToastService } from 'app/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AddToFavoritesService {

  private myStorageKey:string = 'FavoritesList'; //search for key into LocalStorage
  private myFavorites: FavoritesAPI[] = [];
  private _favoritesItemsCount: BehaviorSubject<number> = new BehaviorSubject<number>(this.getFavoritesItemsCountFromLS());

  constructor( 
    private router: Router,
    private toastService: ToastService
  ){

    }

  // Save data to LocalStorage
  saveToFavorites(data: FavoritesAPI){
    const favorites = this.getData();

    if(favorites){

      if (favorites.favoritesData.length >= environment.maxFavoritesSaved) {
        const errorMessage = `Solo puedes guardar un máximo de ${environment.maxFavoritesSaved} favoritos`;
        this.toastService.showToastNotify(errorMessage)
        return;
      }

      this.addToFavoritesList(data, favorites);
    }else{
      // set
      data.dateSaved = new Date();
      this.myFavorites.push(data);
      this.saveItems();
    }

    const addedMessage = 'Guardado en tus Favoritos';
    this.toastService.showToastOffer(addedMessage, data.title);
    this.setFavoritesItemsCount();
  }

  addToFavoritesList(data: FavoritesAPI, favorites: any){
    // parse and set
    data.dateSaved = new Date();
    const concatedArr = favorites.favoritesData.concat(data);
    this.myFavorites = concatedArr; 
    this.saveItems();
  }

  // Return Favorites list
  getFavoritesFromLS():Observable<FavoritesAPI[]>{
    const data = this.getData();
    if(data){
      return of(this.removeRepeatItems(data.favoritesData.reverse()));
    }else{
      return of([]);
    }
  }

  removeFavorite(index:number){
    const res = this.getData();
    res.favoritesData.splice(index, 1);
    this.myFavorites = res.favoritesData;
    this.saveItems();
    this.setFavoritesItemsCount();
    const addedMessage = 'Se eliminó de tus Favoritos';
    this.toastService.showToastNotify(addedMessage);
  }

  // Get data from LocalStorage
  private getData(){
    const data = JSON.parse(localStorage.getItem(this.myStorageKey)); 
    if (data) {
      return data;
    }
  }
  
  // save first item into localstorage
  private saveItems(){
    this.myFavorites = this.removeRepeatItems(this.myFavorites);
    const item = JSON.stringify({
      favoritesData: this.myFavorites
    });
    this.setToLocalStorage(item);
  }

  // set item into localstorage key
  private setToLocalStorage(item:string){
    localStorage.setItem(this.myStorageKey, item);
  }

  removeRepeatItems(arr:FavoritesAPI[]){
    const reducedArr = arr.reduce((thing, current) => {
      const x = thing.find(item => item.title === current.title);
      if (!x) {
        return thing.concat([current]);
      } else {
        return thing;
      }
    }, []);
    return reducedArr;
  }
  

  // get favorites items count
  getFavoritesItemsCount(){
    return this._favoritesItemsCount;
  }

  // set favorites items count
  setFavoritesItemsCount(){
    this._favoritesItemsCount
    .next(this.getFavoritesItemsCountFromLS());
  }
  

  goToStart(){
    this.router.navigate(['/']);
  }

  private getFavoritesItemsCountFromLS(){
    let favoritesItemsCount: number; 
    this.getFavoritesFromLS()
    .subscribe( data => {
      favoritesItemsCount = data.length;
    })
    return favoritesItemsCount;
  }
}


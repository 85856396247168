import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// components
import { OffersListComponent } from '@components/offer-list/offers-list.component';

// custom routes
import { departmentRoutes } from './routes/departments-routes';
import { marketingCampaingRoutes } from './routes/marketing-campaings-routes';

const routes: Routes = [
  { 
    path: '', 
    component: OffersListComponent, 
    data: { animation: 'OffersList' } 
  },
  {
    path: 'search',
    component: OffersListComponent,
    data: { animation: 'OffersList' }
  },
  { 
    path: 'show-offerlist/:order-by', 
    component: OffersListComponent, 
    data: { animation: 'OffersList' } 
  },
  { 
    path: 'favorites',
    loadChildren: () => import('@components/favorites/module/favorites.module').then( m => m.FavoritesModule),
    data: { animation: 'FavoritesTransition1' } 
  },
  ...departmentRoutes,
  ...marketingCampaingRoutes,
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<app-typeahead-offcanvas [dataToSearchIn]="currentMasonryItems"></app-typeahead-offcanvas>
<!-- ld+json seo script -->
<app-json-ld *ngIf="showOfferListContent" [arr]="currentMasonryItems" [title]="currentPageTitle" [description]="currentPageDescription"></app-json-ld>
<!-- ld+json seo script -->
<div *ngIf="showOfferListTopContent">
    <div class="container my-2 my-sm-3">
        <div class="row">
            <div class="col-md-12">
                <app-amazon-mexico [referLink]="referLink"></app-amazon-mexico>
                <!-- <app-hot-sale2023 [referLink]="referLink"></app-hot-sale2023> -->
                <!-- <app-hot-sale2022 [referLink]="referLink"></app-hot-sale2022> -->
                <!-- <app-buen-fin2022 [referLink]="referLink"></app-buen-fin2022> -->
                <!-- <app-black-friday2022 [referLink]="referLink"></app-black-friday2022> -->
            </div>
        </div>
    </div>

    <!-- TODO: ¿Refactor the following condition using [ngClass]? -->
    <div class="container-fluid px-sm-2 my-sm-2 mt-2 mb-1"
        *ngIf="showOfferListContent && !applyHiddenStyleOfferListContent">
        <div class="row">
            <div class="d-flex justify-content-end align-items-center">
                <button type="button" class="d-none d-sm-block | btn me-1" (click)="moveScrollNext()" [disabled]="scrollStarted"
                    [ngClass]="[scrollStarted ? 'btn-outline-secondary' : 'btn-secondary']">
                    <i class="fa-solid fa-chevron-left"></i>
                </button>
                <!-- hidden on mobile -->
                <div #scrollableDiv app-deparment-scrolling-component (scroll)="onScroll($event)"
                    id="department-scroll" class="a d-flex flex-nowrap | scrolling-wrapper | custom-scroll | snap-type-x" 
                    style="overflow-x: auto; padding-bottom: 3px;" [extraClass]="'d-none d-sm-block'">
                </div>
                <button type="button" class="d-none d-sm-block | btn btn-outline-secondary ms-1" (click)="moveScrollBack()" [disabled]="scrollEnded"
                    [ngClass]="[scrollEnded ? 'btn-outline-secondary' : 'btn-secondary']">
                    <i class="fa-solid fa-chevron-right"></i>
                </button>
                <app-order-by-criteria-offcanvas 
                    (onChangedOrderByCriteria)="setCurrentOrderByCriteria($event)"
                    [currentPage]="currentPage">
                </app-order-by-criteria-offcanvas>
            </div>
            <div class="d-flex align-items-center">
                <!-- visible only on desktop -->
                <div app-deparment-scrolling-component
                    class="a d-flex flex-nowrap | scrolling-wrapper | custom-scroll | snap-type-x | mt-2"
                    style="overflow-x: auto; padding-bottom: 3px;" [extraClass]="'d-block d-sm-none'">
                </div>
            </div>
        </div>
    </div>
</div>

<app-placeholder-container></app-placeholder-container>


<div class="offer-list main-container">
    <app-offer-card-container 
        *ngIf="showOfferListContent"
        [offers-param]="currentMasonryItems"
        [ngClass]="hiddenOfferListContent ? 'visibility:hidden;' : 'visibility:visible;'">
    </app-offer-card-container>
</div>

<div *ngIf="showOfferListContent" class="container">
    <div class="row">
        <div class="col-md-12 mb-5 mt-2">
            <div class="a d-flex justify-content-end align-items-center">
                <p class="lead mb-0 updated-ago-info">
                    <i class="fa-regular fa-calendar me-1"></i>
                    Actualizado {{ currentMasonryItems[0].date | updatedAgo | lowercase }}
                </p>
            </div>
        </div>
    </div>
</div>
<app-my-back-to-top></app-my-back-to-top>
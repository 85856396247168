
<ng-container *ngFor="let item of myAvailableDepartments; let i = index; let isFirst = first; let isLast = last;">
    <div *ngIf="i === 0"
        class="d-flex justify-content-center align-items-center snap-aling-start">
        <div class="a d-none d-sm-block">
            <p class="lead font-regular m-0 mx-2 text-muted" 
                [ngClass]="[extraClass ? extraClass : '']">
                Departamentos
            </p>
        </div>
    </div>
    <div class="a d-flex justify-content-center align-items-center snap-aling-start me-2"
        [ngClass]="[extraClass ? extraClass : '', currentRouterUrl.includes(item.route) ? 'order-0' : 'order-1']">
        <!-- if current department is visited -->
        <a *ngIf="currentRouterUrl.includes(item.route); else elseBlock;" 
            class="d-flex justify-content-center align-items-center btn btn-secondary" role="button">
            <p class="lead font-regular d-inline-block text-truncate m-0">
                {{ item.displayName }}
            </p>
            <i class="fa-solid fa-check ms-1"></i>
        </a>
        <ng-template #elseBlock>
            <a class="d-flex justify-content-center align-items-center btn btn-outline-secondary" [routerLink]="'/' + item.route">
                <p class="lead font-regular d-inline-block text-truncate m-0">
                    {{ item.displayName }}
                </p>
                <span [innerHTML]="item.iconTemplate" class="ms-1"></span>
            </a>
        </ng-template>
    </div>
    <div *ngIf="isLast && isDepartment" 
        class="a d-flex justify-content-center align-items-center order-2 snap-aling-start"
        [ngClass]="[extraClass ? extraClass : '']">
        <a class="d-flex justify-content-center align-items-center btn btn-outline-secondary" routerLink="/">
            <p class="lead font-regular d-inline-block text-truncate m-0">
                Todas las Ofertas
            </p>
            <i class="fa-solid fa-trophy ms-1"></i>
        </a>
    </div>
</ng-container>
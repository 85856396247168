<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/image/logo.png" alt="Logotipo de Ofertas del Día" class="img-fluid logo-img">
        </a>
        <div class="a d-flex align-items-center justify-content-center">
            <!-- mobile and tablets search button -->
            <div class="a d-lg-none">
                <a class="btn btn-outline-secondary me-1"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasTypeahead" aria-controls="offcanvasTypeahead">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </a>
                <a class="btn btn-outline-secondary" routerLink="/favorites"
                    [routerLinkActive]="['item-is-active-mini']" role="button">
                    <i class="fa-solid fa-crown"></i>
                    <span *ngIf="favoritesItemsCount >= 1" class="badge bg-light">{{favoritesItemsCount}}</span>
                </a>
            </div>
        </div>

        <div class="a d-none d-lg-block">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
                aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarColor01">
            <ul class="navbar-nav me-auto">
                <li class="a nav-item d-flex align-items-center hand-cursor">
                    <button type="button" class="btn btn-outline-light btn-lg offcanvas-toggle" [attr.accesskey]="'k'"
                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasTypeahead" aria-controls="offcanvasTypeahead">
                        <div class="d-flex align-items-center my-1">
                            <i class="fa-solid fa-magnifying-glass me-1"></i>
                            Buscar ofertas
                            <span class="badge bg-light fw-light key-shortcut ms-1 lead">ALT + K</span>
                        </div>
                    </button>
                </li>
                <li class="nav-item hand-cursor">
                    <a class="nav-link mx-2 px-2 rounded" routerLink="/favorites" [routerLinkActive]="['item-is-active']">
                        <i class="fa-solid fa-crown"></i>
                        <span *ngIf="favoritesItemsCount >= 1"
                            class="badge count-notif mt-1">
                            {{favoritesItemsCount}}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>